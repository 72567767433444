<template>
  <div v-if="servicePickerVisible" class="services">
    <div class="row">
      <div class="col-md-12 mt-3 mb-2 accordionHeader">
        <button
          type="button"
          :class="serviceListToggleOpen ? 'open-accordion' : 'close-accordion'"
          @click="setServiceListToggleOpen"
        >
          <h6>
            Services
          </h6>
          <svg class="h-5 w-5 text-gray-400" viewBox="0 0 10 10" fill="none" stroke="#000">
            <path d="M7 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>
    </div>
    <list
      v-if="serviceListToggleOpen"
      :data="this.services"
      :descriptionContentEnabled="$store.getters.offeringDescriptionEnabled"
      :selectedData="this.service"
      listed="services"
      :type="isClinicService ? 'clinic' : 'practitioner'"
      @selected="selectService"
      :image-field="$store.state.offeringPhoto ? 'cover_image' : false"
      :additionalFields="['duration', 'price_display']"
      :colsInOneRow="4"
      :detailsField="'description'"
      :additionalFields2="true"
      :additionalFieldsPreFix="['', '']"
      :additionalFieldsPostFix="[' mins | ', '']"
      button-label="Select"
    />
  </div>
</template>

<script>
import colorMixin from "../mixins/colorMixin";
import List from './partials/List.vue';

export default {
  mixins: [colorMixin],
  data() {
    return {
      clinicServices: [],
      practitionerServices: [],
      isPractitionerService: null,
      serviceListToggleOpen: true,
    };
  },
  computed: {
    isClinicService() {
      return (
        this.$store.getters.practitioner ? true : false
      );
    },
    servicePickerVisible() {
      let normalView = (
        !this.$store.state.service
        && this.$store.getters.services && this.$store.getters.services.length > 0
      );
      return normalView;
    },
    practitionerSelected() {
      return this.$store.state.practitioner;
    },
    services() {
      return this.$store.getters.services;
    },
    service() {
      return this.$store.getters.service;
    }
  },
  watch: {
    services() {
      this.$store.dispatch('setBusy', false);
    },
  },
  methods: {
    setServiceListToggleOpen() {
      this.serviceListToggleOpen = !this.serviceListToggleOpen;
    },
    async selectService(serviceSelectionObj) {
      await this.$store.dispatch(
        'serviceUpdate',
        {
          service: serviceSelectionObj.selected,
          isPractitionerService: serviceSelectionObj.type === "practitioner" ? true : false
        }
      );
    },
  },
  components: {
    List,
  },
};
</script>
<style>
.accordionHeader h6 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
  font-size: 100%;
  padding-bottom: 0.5rem;
}
.accordionHeader button.close-accordion svg {
  transform: scale(0.4) rotate(180deg);
  top: 8px;
  right: 11px;
}
.accordionHeader button svg {
  transform: scale(0.4);
  top: 0;
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 200;
  right: 6px;
}
.accordionHeader button {
  display: inline-block;
  width: 100%;
  height: 3em;
  text-align: left;
  border: 0;
  padding: 0;
  background: transparent;
  outline: none;
  margin-top: 1rem;
}
</style>

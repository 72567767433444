<template>
  <div class="btn-group-vertical" role="group">
    <button
      v-if="selectedTime && $store.getters.practitioner && practitionerId === $store.getters.practitioner.id"
      type="button"
      :onMouseOver="timeButtonHover"
      :onMouseOut="mouseOut({time: selectedTime})"
      :style="'background-color:' + selected({time: selectedTime})"
      :class="['btn noShadowNoOutline']"
      @click="timeButtonClick(selectedTime)"
    >
      <span>{{ selectedTime | formatTimeAmPm }}</span>
    </button>
    <button
      v-for="(time, key) in getTimes"
      :key="key"
      type="button"
      :onMouseOver="timeButtonHover"
      :onMouseOut="mouseOut(time)"
      :style="'background-color:' + selected(time)"
      :class="['btn noShadowNoOutline']"
      @click="timeButtonClick(time)"
    >
      <span>{{ time.time | formatTimeAmPm }}</span>
    </button>
  </div>
</template>
<script>
import colorMixin from "../../mixins/colorMixin";
export default {
  props: [
    "times",
    "practitionerId",
    "selectedTime",
  ],
  mixins: [colorMixin],
  data() {
    return {
      timeButtonNormal: 'this.style.backgroundColor="' + this.mixinCalendarActiveDayBackgroundColour() + '"',
      timeButtonHover: 'this.style.backgroundColor="' + this.mixinCalendarActiveDayTextColour() + '"',
    };
  },
  computed: {
    getTimes() {
      if (!this.selectedTime) {
        return this.times;
      }
      return this.times.filter(time => time.time !== this.selectedTime)
    }
  },
  methods: {
    mouseOut(time) {
      return (
        (
          this.selectedTime === time.time
          && this.practitionerId === this.$store.getters.practitioner.id
        )
        ? this.timeButtonHover
        : this.timeButtonNormal
      )
    },
    selected(time) {
      return (
        (
          this.selectedTime === time.time
          && this.practitionerId === this.$store.getters.practitioner.id
        )
        ? this.mixinCalendarActiveDayTextColour()
        : this.mixinCalendarActiveDayBackgroundColour()
      );
    },
    timeButtonClick(time) {
      let obj = { time };
      if (this.practitionerId) {
        obj.practitionerId = this.practitionerId;
      }
      this.$emit("timeButtonClick", obj);
    },
  }
}
</script>
<style>
  .button-group-vertical {
    align-items: normal;
  }
</style>
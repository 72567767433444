<template>
  <div
    type="button"
    class="dropdown-btn btn btn-md pr-0 pl-0 pt-0 pb-0"
    :class="[type, !modalActive ? 'no-click' : '']"
  >
    <div class="row">
      <div
        v-if="selectedData"
        @click="toggleModalVisibility"
        class="col"
      >
        <div
          class="centerImage serviceImgRoundedCorners"
          v-if="imageEnabled"
        >
          <img
            :src="imageSrc(selectedData)"
            class="serviceImgRoundedCorners"
            :class="type"
          >
        </div>
        <span class="titleAndAdditionalDetails selected" :class="[type, imageEnabled ? '' : 'no-image']">
          <strong>{{ selectedDataTitle }}</strong>
          <small :style="mixinHighlightStyle()" class="typeOfSelection" :class="type">
            <strong>
              <span v-if="type === 'service'">{{type | ucFirst}}</span>
              <span v-else-if="type === 'practitioner'">{{type | ucFirst}}</span>
              <span v-else-if="type === 'package'">
                {{`Package` | ucFirst}}
                <span class="black"> - {{$store.getters.package.name}}</span>
              </span>
            </strong>
          </small>
          <small
            v-for="(a, index) in additionalFields"
            :key="a"
          >
            {{ additionalFieldsPreFix && additionalFieldsPreFix[index] ? additionalFieldsPreFix[index] : '' }}
            {{ selectedData[a] }}
            {{ additionalFieldsPostFix && additionalFieldsPostFix[index] ? additionalFieldsPostFix[index] : '' }}
          </small>
        </span>
        <svg v-if="resetButtonEnabled" class="h-5 w-5 text-gray-400" :class="dropDownClass" viewBox="0 0 15 15" fill="none" :stroke="mixinCalendarActiveDayTextColour()">
          <path d="M7 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div
        v-else
        class="col nothingSelected"
        @click="toggleModalVisibility"
      >
        <div class="buttonLabel">{{ buttonLabel }}</div>
        <div class="svgIcon">
          <svg class="h-5 w-5 text-gray-400" viewBox="0 0 15 15" fill="none" :class="dropDownClass" :stroke="mixinCalendarActiveDayTextColour()">
            <path d="M7 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colorMixin from "../../mixins/colorMixin";
export default {
  props: [
    "data",
    "type",
    "selectedData",
    "selectedDataTitle",
    "resetButtonEnabled",
    "modalActive",
    "buttonLabel",
    "dropDownTextColor",
    "imageField",
    "additionalFields",
    "additionalFieldsPreFix",
    "additionalFieldsPostFix",
  ],
  mixins: [colorMixin],
  data() {
    return {
      modalShow: false,
    };
  },
  computed: {
    imageEnabled() {
      return this.imageField !== false;
    },
    showModal() {
      return this.modalShow ? 'show' : '';
    },
    modalVisibilityClass() {
      return this.modalShow ? ['show', 'showModal'] : '';
    },
    dropDownClass() {
      return 'selected-' + this.type;
    }
  },
  methods: {
    imageSrc(selectedData) {
      if (!this.imageEnabled) return;

      return selectedData[this.imageField]
        ? selectedData[this.imageField]
        : this.$store.getters.clinic.logo;
    },
    toggleModalVisibility() {
      if (this.modalActive === false) {
        return;
      }

      this.$store.commit('setDropDownModalVisibility', {
        bool: !this.$store.getters.dropDownModalVisibility(this.type),
        type: this.type
      });
    },
    resetData() {
      console.log(this.resetEvent);
      this.$emit('resetEvent', {
        type: this.type
      });
    }
  },
};
</script>

<style>
div.nothingSelected .buttonLabel {
  flex: .9;
  text-align: left;
}
div.nothingSelected .svgIcon {
  flex: .1;
}
div.nothingSelected .svgIcon,
div.nothingSelected .buttonLabel {
  flex-direction: column;
  font-size: 90%;
}
div.nothingSelected {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
svg.selected-practitioner {
  position: absolute;
  top: 12px;
  right: -30px;
}
svg.selected-service {
  position: absolute;
  top: 0;
  right: -30px;
}
.dropdown-btn .centerImage img {
  max-height: 100%;
  /* height: 100%; */
  width: auto;
}
.dropdown-btn .centerImage {
  position: relative;
  cursor: pointer;
  height: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
/* div.centerImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
} */
div.dropdown-btn.service div.centerImage img {
  /* width: 100%; */
}
div.dropdown-btn.practitioner div.centerImage {
  display: flex;
  justify-content: center;
  align-items: left;
  width: 40%;
  height: 78px;
  float: left;
}
@media (max-width: 991.98px) {
  svg.selected-practitioner,
  svg.selected-service {
    right: 0;
  }
  div.dropdown-btn.service div.centerImage img {
    width: 70%;
  }
}
@media (max-width: 767.98px) {
  svg.selected-practitioner,
  svg.selected-service {
    right: 0;
  }
  div.dropdown-btn.service div.centerImage img {
    width: 50%;
  }
}
@media (max-width: 575.98px) {
  svg.selected-practitioner,
  svg.selected-service {
    right: 10px;
  }
  div.dropdown-btn.service div.centerImage img {
    width: 30%;
  }
}
.titleAndAdditionalDetails.no-image.selected.service strong {
  font-size: 110%;
}
.titleAndAdditionalDetails.no-image.selected.service,
.titleAndAdditionalDetails.no-image.selected.practitioner {
  width: 100%;
  float: none;
  text-align: left;
}
.titleAndAdditionalDetails.selected.practitioner strong {
  line-height: 1rem;
}
.titleAndAdditionalDetails.selected.practitioner {
  width: 60%;
  float: right;
  text-align: left;
}
.titleAndAdditionalDetails.selected.practitioner {
  width: 60%;
  float: right;
  text-align: left;
}
img.practitioner {
  float: left;
}
.titleAndAdditionalDetails {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  text-align: left;
  line-height: 1;
  margin-top: 1rem;
}
.titleAndAdditionalDetails .typeOfSelection span.black {
  color: black;
  font-weight: 400;
}
.titleAndAdditionalDetails .typeOfSelection {
  text-align: left;
  display: block;
  line-height: 16px;
  margin-top: 0px;
}
.serviceImgRoundedCorners {
  border-radius: 5px;
}
span.inline-block.rounded-md {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}
span.inline-block.rounded-md .reset-selection {
  z-index: 100;
  padding: 0;
  top: unset;
  right: unset;
}
.dropdown-btn:hover {
  background-color: #eee;
}
.dropdown-btn.no-click {
  cursor: default !important;
}
.dropdown-btn.no-click:hover {
  background-color: transparent !important;
}
span.inline-block.rounded-md .dropdown-btn {
  line-height: inherit;
}
span.inline-block.rounded-md .dropdownIcon {
  left: 1rem;
  position: relative;
}
/*
.dropdown-btn.practitioner .dropdownIcon {
  top: 15%;
}
*/
.close-modal-dropdown {
  font-size: 1.5rem;
}
.dropdown-btn span.selected {
}
.dropdown-btn img {
  width: 5rem;
}
.dropdown-btn {
  width: 100%;
  line-height: 3;
}
.dropdown-ul {
  margin: 0 0 0 0 !important;
  padding-right: 2.5rem !important;
}
.w-full {
  overflow: hidden;
}
.absolute {
  position: absolute;
  z-index: 200;
}
.rounded-md {
  border-radius: 3px;
}
.bg-white {
  background: white;
}
.dropdown-li:hover,
.selected-dropdown-li {
  background-color: #eee;
}
.cursor-pointer {
  cursor: pointer;
}
.h-5 {
  height: 1.5rem;
}
.w-5 {
  width: 1.5rem;
}
.div-in-li {
  overflow: hidden;
  width: 100%;
}
.span-in-li {
  right: -2.5rem;
}
.div-in-li img {
  margin-right: 1rem;
}
</style>
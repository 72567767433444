<template>
  <div class="swandoola-appointment-booker">
    <div class="container">
      <busy v-if="busyMain"></busy>
      <div
        v-else-if="!busy"
        class="row"
        :class="background ? 'bg-light' : ''"
        :style="mixinBackground()"
      >
        <div class="col">
          <!-- is there is snippet then we came back from payment page so it's ok to have all the consultation details but otherwise not-->
          <button-frame
            v-if="!$store.getters.consultation || params.has('snippet')"
            :payment-details="$store.getters.consultation"
          />
          <!-- part of the normal redirect process, after payment, user journey leads us back to the snippet's thank you page -->
          <thank-you
            v-else-if="$store.getters.consultation && !params.has('snippet')"
            :consultation="consultation"
            :btnBgColor="defaultBtnBgcolor"
            :dropDownTextColor="defaultDropDownTextColor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'es6-promise/auto';
import Vue from 'vue';
import Busy from "./appointment-booker/partials/Busy";
import ButtonFrame from "./appointment-booker/ButtonFrame";
import ThankYou from "./appointment-booker/ThankYou";
import moment from "moment";
import store from '../store/appointmentBooker';
import colorMixin from "./mixins/colorMixin";

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faInfoCircle,
  faTimes,
  faCircleNotch,
  faExclamationSquare,
  faCheckSquare,
  faExclamationCircle,
  faSquare,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faInfoCircle)
library.add(faTimes)
library.add(faCircleNotch)
library.add(faExclamationSquare)
library.add(faCheckSquare)
library.add(faExclamationCircle)
library.add(faSquare)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.filter('formatDate', (date) => {
  return moment(date).format("LL");
});
Vue.filter('formatTime', (time) => {
  return time.substr(0, time.lastIndexOf(":"));
});
Vue.filter('formatDateTimeDetailed', (date) => {
  return moment.utc(date).local().format("LLLL");
});
Vue.filter('ucFirst', (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
});
Vue.filter('formatTimeAmPm', (time) => {
  if (time) {
    let noSeconds = '';
    if (time.lastIndexOf(":") > 3) {
      noSeconds = time.substr(0, time.lastIndexOf(":"));
    } else if (time.lastIndexOf('am') > -1) {
      noSeconds = time.substr(0, time.lastIndexOf("am"));
    } else if (time.lastIndexOf('pm') > -1) {
      noSeconds = time.substr(0, time.lastIndexOf("pm"));
    } else {
      noSeconds = time;
    }
    if (noSeconds > "12:00") {
      return noSeconds + "pm";
    } else {
      return noSeconds + "am";
    }
  }
  return time;
});

import axios from "axios";
export default {
  mixins: [colorMixin],
  store,
  props: [
    "clinic",
    "uid",
    "settings",
    "scenario",
    "scenarioText",
    "headerOn",
    "paymentFreeService",
    "paymentAdditionalInformation",
    "thankyouSuccessMessage",
    "thankyouFailedMessage",
    "clinicLogoAlign",
    "clinicLogoVisible",
    "clinicNameVisible",
    "offeringPhoto",
    "offeringDescription",
    "practitionerPhoto",
    "practitionerBio",
    "redirect",
    "btnBgColor",
    "dropdownTextColor",
    "background",
    "firstView",
    "defaultPractitionerId",
    "defaultServiceId",
    "detaultPackageId",
    "sortedFilteredPractitioners",
    "sortedFilteredServices",
    "sortedFilteredPackages",
    "sortedFilteredTncs",
    "singlePractitionerTileVisible",
    "allowedServices",
    "allowedPractitioners",
    "clientDetailsCaptured",
    "serviceFirst",
    "skipPractitioners",
    "customStyles",
  ],
  name: "AppointmentBooker",
  data() {
    return {
      loadingSnippet: true,
      snippet: null,
      consultation: null,
      busy: false,
      params: null,
      defaultBtnBgcolor: null,
      defaultDropDownTextColor: null,
    };
  },
  watch: {
    scenarioText() {
      this.$store.commit('setScenarioText', this.scenarioText)
      this.$store.dispatch('setScenario', this.scenario ? parseInt(this.scenario) : '')
    },
    async clientDetailsCaptured() {
      await this.$store.dispatch('resetSnippet');
      this.$store.commit('setClientDetailsCaptured', this.clientDetailsCaptured)
      this.$store.dispatch('setScenario', this.scenario ? parseInt(this.scenario) : '')
    },
    headerOn() {
      this.$store.commit('setHeaderOn', this.headerOn)
    },
    clinicLogoAlign() {
      this.$store.commit('setClinicLogoAlign', this.clinicLogoAlign)
    },
    clinicLogoVisible() {
      this.$store.commit('setClinicLogoVisible', this.clinicLogoVisible)
    },
    clinicNameVisible() {
      this.$store.commit('setClinicNameVisible', this.clinicNameVisible)
    },
    offeringPhoto() {
      this.$store.commit('setOfferingPhoto', this.offeringPhoto)
    },
    offeringDescription() {
      this.$store.commit('setOfferingDescription', this.offerinDescription)
    },
    practitionerPhoto() {
      this.$store.commit('setPractitionerPhoto', this.practitionerPhoto)
    },
    practitionerBio() {
      this.$store.commit('setPractitionerBio', this.practitionerBio)
    },
    customStyles() {
      this.$store.commit('setStyles', JSON.parse(this.customStyles))
    },
    sortedFilteredPractitioners() {
      console.log("Sorted filtered practitioners:", this.sortedFilteredPractitionerIds);
      this.$store.dispatch('setSortedFilteredPractitionerIds', this.sortedFilteredPractitioners);
    },
    sortedFilteredServices() {
      console.log("Sorted filtered services:", this.sortedFilteredServices);
      this.$store.dispatch('setSortedFilteredServiceIds', this.sortedFilteredServices);
    },
    sortedFilteredPackages() {
      console.log("Sorted filtered packages:", this.sortedFilteredPackages);
      this.$store.dispatch('setSortedFilteredPackageIds', this.sortedFilteredPackages);
    },
    sortedFilteredTncs() {
      console.log("Sorted filtered tncs:", this.sortedFilteredTncs);
      this.$store.dispatch('setSortedFilteredTncIds', this.sortedFilteredTncs);
    }
  },
  computed: {
    busyMain() {
      return this.$store.getters.busyMain;
    }
  },
  methods: {
    fetchSnippet() {
      return axios.get(process.env.VUE_APP_API_URL + '/api/front-end/appointment-snippet-configs/' + this.uid).then(({data}) => {
        this.snippet = data;

        if (this.snippet) {
          // unpack snippet into the current madness until we delete it all and start fresh
          this.clinic = this.snippet.clinic_id;
          this.scenarioText = this.translateNewStepsToOldScenarioText(this.snippet.steps);
          this.paymentFreeService = this.snippet.msg_payment_free_service;
          this.paymentAdditionalInformation = this.snippet.msg_payment_additional_info;
          this.thankyouSuccessMessage = this.snippet.msg_thank_you_success;
          this.clientDetailsCaptured = this.snippet.steps[0] === 'client_details' ? 'first' : 'last';
          this.headerOn = this.snippet.enable_header;
          this.clinicLogoAlign = this.snippet.align_clinic_logo;
          this.clinicLogoVisible = this.snippet.enable_clinic_logo;
          this.clinicNameVisible = this.snippet.enable_clinic_name;
          this.offeringPhoto = this.snippet.enable_service_photo;
          this.offeringDescription = this.snippet.enable_service_description;
          this.practitionerPhoto = this.snippet.enable_practitioner_photo;
          this.practitionerBio = this.snippet.enable_practitioner_bio;

          this.sortedFilteredServices = [];
          this.sortedFilteredPackages = [];
          this.sortedFilteredPractitioners = [];
          if (this.snippet.services) {
            this.sortedFilteredServices = this.snippet.services.map(item => item.id).join(",")
          }
          if (this.snippet.packages) {
            this.sortedFilteredPackages = this.snippet.packages.map(item => item.id).join(",")
          }
          if (this.snippet.practitioners) {
            this.sortedFilteredPractitioners = this.snippet.practitioners.map(item => item.id).join(",")
          }
          if (this.snippet.terms) {
            this.sortedFilteredTncs = this.snippet.terms.map(item => item.id).join(",")
          }

          this.customStyles = JSON.stringify({
            "generic":{
              "label":"Generic",
              "items":{
                "mainColor":{"label":"Main colour","value":this.snippet.color_main},
                "warningColor":{"label":"Warning colour","value":this.snippet.color_warning},
                "errorColor":{"label":"Error colour","value":this.snippet.color_error}
              }
            },
            "button":{
              "label":"Button & Icons",
              "items":{
                "activeColor":{"label":"Active colour","value":this.snippet.color_btn_active},
                "disabledColor":{"label":"Disabled colour","value":this.snippet.color_btn_disabled},
                "backgroundColor":{"label":"Background colour","value":this.snippet.color_btn_background},
                "fontColor":{"label":"Font colour","value":this.snippet.color_btn_text}
              }
            },
            "calendar":{
              "label":"Calendar",
              "items":{
                "activeDayColor":{"label":"Active days colour","value":this.snippet.color_calendar_active_days}
              }
            },
            "dropDown":{
              "label":"Dropdowns",
              "items":{"borderColor":{"label":"Border colour","value":this.snippet.color_dropdown_border},
                "textColor":{"label":"Text colour","value":this.snippet.color_dropdown_text}
              }
            },
            "list":{
              "label":"List",
              "items":{
                "textColor":{"label":"Text colour","value":this.snippet.color_list_text}
              }
            },
            "background":{
              "label":"Background",
              "width":50,
              "items": {
                "image":{"label":"Background Image:","value":null,"type":"file","name":"backgroundImage"},
                "color":{"label":"Background Colour:","value":this.snippet.color_background}
              }
            },
            "header":{
              "label":"Header",
              "width":50,
              "items":{
                "image":{"label":"Background Image:","value":null,"type":"file","name":"backgroundHeaderImage"},
                "color":{"label":"Background Colour:","value":this.snippet.color_header_background}
              }
            }
          });

          this.$store.commit('setClinicId', this.clinic);
        }

        this.loadingSnippet = false;
      })
    },
    translateNewStepsToOldScenarioText(steps) {
      let ret = steps.filter((item) => {
        return item !== 'client_details' && item !== 'payment'
      }).map((item) => {
        if (item === 'practitioner') {
          return 'practitioners'
        } else if (item === 'service') {
          return 'services';
        } else if (item === 'date') {
          return 'calendar';
        }
        return item;
      })

      ret.push('confirm');
      return ret.toString()
    },
    backgroundStyle() {
      const ret = this.colorMixin.mixinBackground();
      if (!ret) {
        return ''
      }
      return ret
    },
    async setScenario(event = false) {
      this.$store.dispatch('setScenario', parseFloat(event.target.value));
    },
    async setClinicId(event) {
      this.$store.commit('setClinicId', event.target.value);
      await this.$store.dispatch('isFetchClinicsPractitioners');
    }
  },
  async mounted() {
    if (this.uid) {
      await this.fetchSnippet();
    }

    this.params = new URLSearchParams(window.location.search);
    let scenario = this.scenario ? this.scenario : this.params.get('scenario')
    if (scenario) {
      scenario = parseInt(scenario)
    }
    let scenarioText = this.scenarioText
    if (this.params.has('scenario') && !scenarioText) {
      switch (scenario) {
        case 1:
          scenarioText = 'practitioners,services,calendar,confirm'
          break;
        case 2:
          scenarioText = 'services,practitioners,calendar,confirm'
          break;
        case 3:
          scenarioText = 'services,calendar,practitioners,confirm'
          break;
      }
    }

    this.$store.dispatch('setSortedFilteredPractitionerIds', this.sortedFilteredPractitioners);
    this.$store.dispatch('setSortedFilteredServiceIds', this.sortedFilteredServices);
    this.$store.dispatch('setSortedFilteredPackageIds', this.sortedFilteredPackages);
    this.$store.dispatch('setSortedFilteredTncIds', this.sortedFilteredTncs);
    this.$store.commit('setClinicId', this.clinic);

    this.$store.commit(
        'setDefaultParams',
        {
          clinicId: this.clinic,
          allowedPractitioners: this.allowedPractitioners,
          allowedServices: this.allowedServices,
          practitionerId: this.defaultPractitionerId,
          serviceId: this.defaultServiceId,
          packageId: this.defaultPackageId,
          redirect: this.redirect,
          singlePractitionerTileVisible: this.singlePractitionerTileVisible,
          serviceFirst: this.serviceFirst,
          skipPractitioners: this.skipPractitioners,
        }
    );

    this.$store.commit('setScenarioText', scenarioText);
    await this.$store.dispatch('setScenario', scenario);

    this.$store.commit('setStyles', this.customStyles ? JSON.parse(this.customStyles) : {});
    this.$store.commit('setHeaderOn', this.headerOn);
    this.$store.commit('setClientDetailsCaptured', this.clientDetailsCaptured);
    this.$store.commit('setClinicLogoAlign', this.clinicLogoAlign);
    this.$store.commit('setClinicLogoVisible', this.clinicLogoVisible);
    this.$store.commit('setClinicNameVisible', this.clinicNameVisible);
    this.$store.commit('setOfferingPhoto', this.offeringPhoto);
    this.$store.commit('setOfferingDescription', this.offeringDescription);
    this.$store.commit('setPractitionerPhoto', this.practitionerPhoto);
    this.$store.commit('setPractitionerBio', this.practitionerBio);
    this.$store.commit('setThankyouSuccessMessage', this.thankyouSuccessMessage);
    this.$store.commit('setThankyouFailedMessage', this.thankyouFailedMessage);
    this.$store.commit('setPaymentAdditionalInformation', this.paymentAdditionalInformation);
    this.$store.commit('setPaymentFreeService', this.setPaymentFreeService);

    // Check for a redirect from the swandoola app
    await this.$store.dispatch('fetchConsultation', this.params);
  },
  components: {
    ButtonFrame,
    Busy,
    ThankYou,
  },
};
</script>

<style lang="scss">

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@import "~bootstrap/scss/bootstrap";
.swandoola-appointment-booker,
create-appointment,
payment-details {
  font-family: "Inter", sans-serif !important;
}
html {
  -webkit-tap-highlight-color:transparent;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #aaa;
}
::-webkit-scrollbar-thumb:hover {
  background: #777;
}
*:focus {outline:none !important}
.percentagePadding {
  padding-left: 5%;
  padding-right: 5%;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import moment from 'moment';

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state: {
    count: 0,
    lastSelectedService: null,
    isServiceSourceChanged: null,
    currentStep: 1,
    client: null,
    clinicId: null,
    clinic: null,
    tncValidated: {},
    clientDetailsValidated: null,
    clientDetails: {
      email: null,
      phone: null,
      first_name: null,
      last_name: null
    },
    phonePrefix: 44,
    clientDetailsSubmitStatus: null,
    tncSubmitStatus: null,
    practitioner: null,
    practitioners: {},
    practitionerServicePairs: {},
    service: null,
    services: null,
    serviceMethod: null,
    backgroundUrl: null,
    headerBackgroundUrl: null,
    singlePractitioner: false,
    singleService: false,
    package: null,
    packages: null,
    tncToValidate: {},
    packageSelected: null,
    packageSelectedService: null,
    sortedFilteredPractitionerIds: null,
    sortedFilteredServiceIds: null,
    sortedFilteredPackageIds: null,
    sortedFilteredTncIds: null,
    date: null,
    time: null,
    defaultPractitionerId: null,
    defaultServiceId: null,
    defaultPackageId: null,
    singlePractitionerTileVisible: null,
    skipPractitioners: false,
    clinicLogoVisible: true,
    clinicNameVisible: true,
    clinicLogoAlign: true,
    headerOn: true,
    clientDetailsCaptured: 'last',
    serviceFirst: false,
    singleSeatedClinic: false,
    scenario: false,
    scenarioText: '',
    busy: true,
    busyMain: false,
    practitionerPhoto: true,
    offeringPhoto: true,
    practitionerAndServiceLoading: false,
    practitionerAndServiceLoadingStep: 0,
    allowedServices: null,
    allowedPractitioners: null,
    consultation: null,
    thankyouSuccessMessage: null,
    thankyouFailedMessage: null,
    paymentAdditionalInformation: null,
    paymentFreeService: null,
    busyProcesses: {
      practitioner: false,
      service: false,
      package: false,
      eventCalendar: undefined,
    },
    orderOfDataCollecting: ['practitioner', 'service'],
    steps: {
      dataCollector: 1,
      eventCalendar: 2,
      clientDetails: 3,
      serviceConfirm: 3,
      packageConfirm: 3,
    },
    stepsNoDropDown: null,
    dropDownModalVisibility: {
      practitioner: false,
      service: false,
    },
    terms: [],
    loadAllPotentialDatesForPractitioners: false,
    alternativeDates: {},
    availableDates: {},
    styles: {
      main: {
        main: '#33ddbb',
        warning: '#33ddbb',
        error: '#33ddbb',
        fontColor: '#000000',
      },
      button: {
        backgroundColor: '#ffffff',
        activeColor: '#11ddbb',
        disabledColor: '#666666',
        fontColor: '#000000',
      },
      calendar: {
        activeDayColor: '#33ddbb',
      },
      dropDown: {
        borderColor: '#33ddbb',
        textColor: '#33ddbb',
      },
      list: {
        // borderColor: '#33ddbb',
        // selectedBorderColor: '#33ddbb',
        textColor: '#000000',
      },
      background: {
        image: '',
        color: '#ffffff',
      },
      header: {
        image: '',
        color: '#ffffff',
      },
    },
    practitionerBio: false,
    offeringDescription: false,

  },
  getters: {
    offeringDescriptionEnabled: state => state.offeringDescription,
    practitionerBioEnabled: state => state.practitionerBio,
    practitionerPhoto: state => state.practitionerPhoto,
    offeringPhoto: state => state.offeringPhoto,
    tncToValidate: state => state.tncToValidate,
    sortedFilteredPractitionerIds: state => state.sortedFilteredPractitionerIds,
    sortedFilteredServiceIds: state => state.sortedFilteredServiceIds,
    sortedFilteredPackageIds: state => state.sortedFilteredPackageIds,
    sortedFilteredTncIds: state => state.sortedFilteredTncIds,
    backgroundUrl: state => state.backgroundUrl,
    headerBackgroundUrl: state => state.headerBackgroundUrl,
    terms: state => state.terms,
    consultation: state => state.consultation,
    phonePrefix: state => state.phonePrefix,
    styles: state => state.styles,
    busyMain: state => state.busyMain,
    clientDetailsValidated: state => state.clientDetailsValidated,
    tncValidated: state => state.tncValidated,
    tncSubmitStatus: state => state.tncSubmitStatus,
    clientDetailsSubmitStatus: state => state.clientDetailsSubmitStatus,
    clientDetailsCaptured: state => state.clientDetailsCaptured,
    clientDetails: state => state.clientDetails,
    clientId: state => state.client,
    defaultPackageId: state => state.defaultPackageId,
    availableDates: state => state.availableDates,
    loadAllPotentialDatesForPractitioners: state => state.loadAllPotentialDatesForPractitioners,
    alternativeDates: state => state.alternativeDates,
    singleSeatedClinic: state => state.singleSeatedClinic,
    dropDownModalVisibility: (state) => (type) => state.dropDownModalVisibility[type] ? state.dropDownModalVisibility[type] : false,
    stepsNoDropDown: (state) => state.stepsNoDropDown,
    isCurrentStep: (state) => (name) => {
      let stepEnabled = state.currentStep === state.steps[name];
      if (state.stepsNoDropDown !== null) {
        let stepIndex = Object.values(state.stepsNoDropDown).indexOf(name);
        stepEnabled = parseFloat(Object.keys(state.stepsNoDropDown)[stepIndex]) === state.currentStep;
      } else if (name === 'serviceConfirm' && state.stepsNoDropDown === null) {
        stepEnabled = stepEnabled && state.clientDetailsValidated
      }
      return stepEnabled;
    },
    currentStep: state => state.currentStep,
    clinicId: state => state.clinicId,
    clinic: state => state.clinic,
    defaultPractitionerId: state => state.defaultPractitionerId,
    defaultServiceId: state => state.defaultServiceId,
    service: state => {
      let ret = state.service;
      if (
        ret
        && ret.allow_video === undefined
        && ret.service
        && ret.service.allow_video !== undefined
      ) {
        return ret.service;
      }
      if (
        ret
        && ret.allow_video === undefined
        && ret.service === undefined
        && ret.practitioner_service
        && ret.practitioner_service.service
        && ret.practitioner_service.service.allow_video !== undefined
      ) {
        return ret.practitioner_service.service;
      }
      return ret;
    },
    serviceMethod: state => state.serviceMethod,
    services: state => state.services,
    date: state => state.date,
    time: state => state.time,
    practitioner: state => state.practitioner,
    practitioners: state => state.practitioners,
    practitionersSortedArray: (state) => {
      let sortedPractitionersArray = Object.values(state.practitioners);
      if (
        !state.sortedFilteredPractitionerIds
        || !state.sortedFilteredPractitionerIds.length
      ) {
        return sortedPractitionersArray;
      }
      sortedPractitionersArray = [];
      state.sortedFilteredPractitionerIds.forEach((practitionerId, pIndex) => {
        if (state.practitioners[practitionerId]) {
          sortedPractitionersArray.push(state.practitioners[practitionerId]);
        }
      })
      return sortedPractitionersArray;
    },
    package: state => state.package,
    packageService: state => state.packageSelectedService,
    packages: state => state.packages,
    thankyouSuccessMessage: state => state.thankyouSuccessMessage,
    thankyouFailedMessage: state => state.thankyouFailedMessage,
    paymentAdditionalInformation: state => state.paymentAdditionalInformation,
    paymentFreeService: state => state.paymentFreeService,
    busyCalendar: state => {
      if (state.busyProcesses.eventCalendar !== undefined) {
        return state.busyProcesses.eventCalendar;
      }
      return false;
    },
    busy: state => {
      if (state.busyProcesses.eventCalendar !== undefined) {
        // if (specificType && specificType === 'eventCalendar') {
          // return state.busyProcesses.eventCalendar;
        // }

        return state.busyProcesses.practitioner || state.busyProcesses.service || state.busyProcesses.package || state.busyProcesses.eventCalendar;
      }
      return state.busyProcesses.practitioner || state.busyProcesses.service || state.busyProcesses.package;
    }
  },
  actions: {
    setClientDetails(context, clientDetails) {
      context.commit('setClientDetails', clientDetails);
      context.commit('setClientDetailsValidated', true);
      if (context.state.clientDetailsCaptured === 'first') {
        context.dispatch('stepsIncrease');
      } else if (context.state.clientDetailsCaptured === 'last') {
        context.dispatch('bookAppointment');
      }
    },
    fetchTerms(context) {
      if (!context.getters.clinic || !context.getters.clinic.id) {
        throw new Error('cannot fetch terms without the clinic set')
      }
      return axios
        .get(process.env.VUE_APP_API_URL_APPOINTMENTS + "/fetch-clinic/terms-published-snippet-only/" + context.getters.clinic.id)
        .then(({ data }) => {
          if (!data.clinicTerms || !data.clinicTerms.length) {
            return
          }
          let terms = data.clinicTerms;
          const tncIds = context.getters.sortedFilteredTncIds;
          console.log(tncIds)
          if (
            tncIds
            && tncIds.length
          ) {
            terms = data.clinicTerms.filter(term => context.getters.sortedFilteredTncIds.lastIndexOf(term.id) > -1)
            terms.sort((a, b) => {
              return tncIds.indexOf(a.id) - tncIds.indexOf(b.id)
            });
          }
          context.commit('setTerms', terms);
        });
    },
    submitClientDetails(context) {
      const data = {
        clinic_id: context.getters.clinicId,
        email: context.getters.clientDetails.email,
        phone: context.getters.clientDetails.phone,
        first_name: context.getters.clientDetails.first_name,
        last_name: context.getters.clientDetails.last_name,
      };
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/save-client",
            data
        )
        .catch((error) => {
          console.log(error);
          context.commit('setClientDetailsSubmitStatus', 'NETWORK_ERROR');
          return;
        })
        .then(({ data }) => {
          if (!data.client.id) {
            console.log('no client id returned', data.message);
          }
          context.commit('setClientId', data.client.id);
          context.commit('setClientDetailsSubmitStatus', 'OK');
        });
    },
    /**
     * Fetch consultation details
     * You need to be careful with the order of the calls in this method
     * @param {*} context
     * @param {*} consultationId
     * @param {*} thankYouPage
     * @returns
     */
    async fetchConsultation (context, params) {
      if (!params.has('consultation')) {
        return;
      }
      context.commit('setBusyMain', true);
      context.dispatch('setBusy', true);

      const consultationId = params.get('consultation');
      const thankYouPage = !params.get('snippet');

      let scenario = context.state.scenario;
      console.log(
        'fetching consultationId:',
        consultationId,
        'thankyoupage:',
        thankYouPage
      );
      await context.dispatch('checkPaymentStatus', consultationId);

      const { email, first_name, last_name, phone, id } = context.getters.consultation.client;

      context.commit('setClientId', id);
      context.commit('setClientDetails', { client: {
        email,
        first_name,
        last_name,
        phone,
      }});

      if (!thankYouPage) {
        context.commit('setCurrentStep', 2); //calendar page
      }
      // await context.dispatch('setScenario', scenario ? parseInt(scenario) : '');
      await context.dispatch('fetchClinic', context.getters.consultation.order.clinic.id);
      context.commit('setDefaultServiceId', context.getters.consultation.service.service.id);
      context.commit('setDefaultPractitionerId', context.getters.consultation.practitioner.id);

      if (context.getters.consultation.clinic_package_id !== null) {
        context.commit('setDefaultPackageId', context.getters.consultation.clinic_package_id);
        await context.dispatch('fetchPackages');
      }
      await context.dispatch('fetchPractitioners');
      await context.dispatch('fetchServices');
      if (!thankYouPage) {
        await context.dispatch('fetchEventDateTimes');
      }
      context.commit('setService', context.getters.consultation.service.service);
      const startDate = new Date(context.getters.consultation.start).toISOString().substring(0, 10);
      const startTime = new Date(context.getters.consultation.start).toLocaleTimeString();
      context.commit('setDateTime', {
        date: startDate,
        time: startTime
      });
      context.commit('setServiceMethod', context.getters.consultation.method);
      context.commit('setClientDetailsValidated', true); //@todo
      context.dispatch('setBusy', false);
      context.commit('setBusyMain', false);
    },
    checkPaymentStatus(context, consultationId) {
      return axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/check-payment/" +
            consultationId
        )
        .then(({ data }) => {
          context.commit('setConsultation', data);
        });
    },
    /**
     * Books an appointment then redirect to payment page with summary on it
     * @param {Object} context
     */
    async bookAppointment(context) {
      context.commit('setBusyMain', true)
      const datetime = moment(context.getters.date + " " + context.getters.time, "YYYY-MM-DD HH:mm:ss")
        .utc()
        .toISOString();
      if (!context.getters.clientId) {
        throw new Error('missing client id')
      }
      let payload = {
        client: context.getters.clientId,
        practitioner: context.getters.practitioner.id,
        method: context.state.serviceMethod,
        datetime, //api expects with lowercase T
      };

      if (context.getters.package) {
        payload['package'] = context.getters.package.id;
      }
      if (context.getters.service && !context.getters.package) {
        // console.log(
        //   1231323321,
        //   context.getters.service,
        //   context.state.practitionerServicePairs,
        //   context.getters.practitioner.id
        // );
        if (context.state.practitionerServicePairs
          && context.state.practitionerServicePairs[context.getters.practitioner.id]
          && Object.keys(context.state.practitionerServicePairs).length > 1 //in case of single practitioner
        ) {
          payload['service'] = context.state.practitionerServicePairs[context.getters.practitioner.id];
        } else {
          //single practitioner
          payload['service'] = context.getters.service.id;
        }
      }
      if (
        context.getters.consultation &&
        context.getters.consultation.id
      ) {
        context.dispatch('redirect', context.getters.consultation)
        return;
      }
      axios
        .post(process.env.VUE_APP_API_URL + "/api/front-end/appointments", payload)
        .then(({ data }) => {
          if (!data.consultation) {
            console.table(data);
            throw Error('Missing consultation')
          }
          context.dispatch('redirect', data.consultation)
        });
    },

    /**
     * Responsible for redirects out from the snippet
     * @param {*} context
     * @param {*} consultation
     */
    redirect(context, consultation) {
      this.confirmed = true;
      this.busy = false;

      console.log('hosts:', context.state.redirect, window.location.href)

      let redirectBack = window.location.href;

      let adin = '';
      if (context.getters.paymentAdditionalInformation) {
        adin = "&adin=" + encodeURIComponent(context.getters.paymentAdditionalInformation)
      }
      let fsr = '';
      if (context.getters.paymentAdditionalInformation) {
        fsr = "&fsr=" + encodeURIComponent(context.getters.paymentFreeService)
      }
      let fc = '';
      if (context.getters.styles.main.fontColor) {
        fc = "&fc=" + encodeURIComponent(context.getters.styles.main.fontColor)
      }
      let mc = '';
      if (context.getters.styles.main.main) {
        mc = "&mc=" + encodeURIComponent(context.getters.styles.main.main)
      }
      let redirectUrl = '';
      if (context.state.redirect) {
        redirectUrl = "&redirectUrl=" + context.state.redirect;
      }

      let url
      if (
        consultation.price > 0 &&
        consultation.paymentStatus !== "paid" &&
        consultation.order
      ) {
        //payment page
        url = process.env.VUE_APP_FRONTEND_URL +
          "/consultations/payment/" +
          consultation.id +
          "?redirect=" +
          redirectBack +
          adin +
          fsr +
          fc +
          mc +
          redirectUrl;
      } else {
        //in case of free service we simply going to the thank you page
        url = window.location.href +
          "?consultation=" +
          consultation.id +
          adin +
          fsr +
          fc +
          mc +
          redirectUrl;
      }
      console.log('url to redirect', url);
      window.location.href = url;
    },

    /**
     * Resets snippet settings back to a fresh start
     * @param {Object} context
     */
    async resetSnippet (context) {
      context.dispatch('setBusy', true);
      context.commit('setCurrentStep', 1);
      context.commit('setClinic', null);
      context.commit('setSinglePractitioner', false);
      context.commit('setSingleSeatedClinic', false);
      context.commit('setServiceMethod', null);
      context.commit('setPackage', null);
      context.commit('setPackageMethod', null);
      context.commit('setPackageService', null);
      context.commit('setAvailableDates', {});
      context.commit('setAlternativeDates', {});
      context.commit('setBusyMain', false);
      context.commit('setClientDetails', { client: null });
      context.commit('setClientDetailsCaptured', null);
      context.commit('setClientDetailsValidated', null);
      context.commit('setDropDownModalVisibility', {
        bool: false,
        type: 'practitioner'
      });
      context.commit('setDropDownModalVisibility', {
        bool: false,
        type: 'service'
      });
    },
    async setScenario (context, scenario) {
      context.commit('setBusyMain', true);
      context.commit('setScenario', scenario);

      let steps = {
        1: 'practitioner',
        1.5: 'service',
        2: 'eventCalendar',
        3: 'clientDetails'
      }
      if (context.state.clientDetailsCaptured === 'first') {
        steps = {
          0: 'clientDetails',
          1: 'practitioner',
          1.5: 'service',
          2: 'eventCalendar',
        };
        context.commit('setCurrentStep', 0)
      }

      console.log('steps', steps, context.getters.currentStep);
      const defaultClinicId = 1;
      switch (scenario) {
        default:

          if (context.state.clinicId === null) {
            throw new Error ('Missing clinic ID');
          }

          switch (context.state.scenarioText) {
            case 'practitioners,services,calendar,confirm':
              context.commit('setServiceFirst', false);
              context.commit('setStepsNoDropDown', steps);
            break;
            case 'services,practitioners,calendar,confirm':
              context.commit('setServiceFirst', true);
              context.commit('setStepsNoDropDown', steps);
            break;
            case 'services,calendar,practitioners,confirm':
              context.commit('setServiceFirst', true);
              context.commit('setSkipPractitioners', true);
              context.commit('setLoadAllPotentialDatesForPractitioners', true);
              delete steps[1.5];
              delete steps[2.5];
              context.commit('setStepsNoDropDown', steps);
            break;
            // default:
              // throw new Error('Unknown scenario');
          }
          await context.dispatch('fetchClinic', context.getters.clinicId);
          await context.dispatch('fetchPractitioners');
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
          await context.dispatch('fetchTerms');
        break;
        case 1:
          context.commit('setStepsNoDropDown', {
            1: 'practitioner',
            1.5: 'service',
            2: 'eventCalendar',
            3: 'clientDetails'
          })
          await context.dispatch('fetchClinic', defaultClinicId);
          context.commit('setServiceFirst', false);
          context.commit('setDefaultServiceId', null);
          context.commit('setDefaultPractitionerId', null);
          context.commit('setSkipPractitioners', false);
          await context.dispatch('fetchPractitioners');
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
          await context.dispatch('fetchTerms');
        break;
        case 1.5:
          context.commit('setClientDetailsCaptured', 'first');
          context.commit('setCurrentStep', 0);
          context.commit('setStepsNoDropDown', {
            0: 'clientDetails',
            1: 'practitioner',
            1.5: 'service',
            2: 'eventCalendar',
          })
          await context.dispatch('fetchClinic', defaultClinicId);
          context.commit('setServiceFirst', false);
          context.commit('setDefaultServiceId', null);
          context.commit('setDefaultPractitionerId', null);
          context.commit('setSkipPractitioners', false);
          await context.dispatch('fetchPractitioners');
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
          await context.dispatch('fetchTerms');
        break;
        case 2:
          context.commit('setStepsNoDropDown', {
            1: 'service',
            1.5: 'practitioner',
            2: 'eventCalendar',
            3: 'clientDetails'
          })
          await context.dispatch('fetchClinic', defaultClinicId);
          context.commit('setServiceFirst', true);
          context.commit('setDefaultServiceId', null);
          context.commit('setDefaultPractitionerId', null);
          context.commit('setSkipPractitioners', false);
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
          await context.dispatch('fetchTerms');
        break;
        case 3:
          context.commit('setStepsNoDropDown', {
            1: 'service',
            2: 'eventCalendar',
            3: 'clientDetails',
          })
          await context.dispatch('fetchClinic', defaultClinicId);
          context.commit('setServiceFirst', true);
          context.commit('setDefaultServiceId', null);
          context.commit('setDefaultPractitionerId', null);
          context.commit('setSkipPractitioners', true);
          context.commit('setLoadAllPotentialDatesForPractitioners', true);
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
          await context.dispatch('fetchTerms');
        break;
        case 4:
          await context.dispatch('fetchClinic', defaultClinicId);
          context.commit('setServiceFirst', true);
          context.commit('setDefaultServiceId', 1);
          context.commit('setDefaultPractitionerId', null);
          context.commit('setSkipPractitioners', true);
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
          await context.dispatch('fetchTerms');
          await context.dispatch('serviceUpdate', {
            service: context.getters.service,
            isPractitionerService: false,
          });
        break;
        case 4.5:
          await context.dispatch('fetchClinic', defaultClinicId);
          context.commit('setServiceFirst', true);
          context.commit('setDefaultServiceId', 549);
          context.commit('setDefaultPractitionerId', null);
          context.commit('setSkipPractitioners', true);
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
          await context.dispatch('fetchTerms');
          await context.dispatch('serviceUpdate', {
            service: context.getters.service,
            isPractitionerService: false,
          });
        break;
        case 5:
          await context.dispatch('fetchClinic', defaultClinicId);
          context.commit('setServiceFirst', false);
          context.commit('setDefaultServiceId', null);
          context.commit('setDefaultPractitionerId', 1);
          context.commit('setSkipPractitioners', false);
          await context.dispatch('fetchPractitioners');
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
          await context.dispatch('fetchTerms');
        break;
      }
      context.commit('setBusyMain', false);
    },
    async fetchDatesForSelectedPractitioner(context) {
      if (
        !context.getters.service
        || !context.getters.service.id
      ) {
        console.log('missing service')
        context.commit('setAvailableDates', {});
        context.commit('setAlternativeDates', {});
        return;
      }

      const url = process.env.VUE_APP_API_URL_APPOINTMENTS + "/fetch-dates";

      const data = {
        practitioner_service_id: context.getters.service.id,
      };

      if (context.getters.practitioner && context.getters.practitioner.id) {
        data.practitioner_id = context.getters.practitioner.id;
      }

      await axios.post(
          url,
          data
        )
        .then(async ({ data }) => {
          let mydates = await context.dispatch('datesToAvailableDates', data);
          if (context.getters.loadAllPotentialDatesForPractitioners === false
            || context.getters.packageSelected !== null
          ) {
            console.log(1002.101);
            context.commit('setAvailableDates', mydates);
          }
          console.log(1002.1);
        });
    },
    async fetchDatesForAlternativePractitioners(context) {
      if (!context.getters.practitioners) {
        context.commit(
          'setBusyProcesses',
          {
            eventCalendar: false,
          }
        );
        console.log(1002.212);
        return;
      }

      const url = process.env.VUE_APP_API_URL_APPOINTMENTS + "/fetch-dates";
      let alternativeDates = {};

      await axios.all(
        Object.values(context.getters.practitioners).map( async practitioner => {
          if (context.state.practitioner && context.state.practitioner.id === practitioner.id) {
            return;
          }

          await axios.post(
            url,
            {
              practitioner_service_id: context.state.service.id,
              practitioner_id: practitioner.id
            }
          ).then( async ({ data }) => {
            let availableDates = await context.dispatch('datesToAvailableDates', data);
            alternativeDates[practitioner.id] = availableDates;
            if (context.getters.practitioner === null) {
              context.commit('setAvailableDates', {
                ...context.state.availableDates, ...availableDates
              });
            }
            console.log(1002.2);
          })
        })
      ).then(() => {
        context.commit('setAlternativeDates', alternativeDates);
        context.commit(
          'setBusyProcesses',
          {
            eventCalendar: false,
          }
        );
        console.log(1002.21);
      });
    },
    datesToAvailableDates(context, data) {
      let availableDates = {};
      for (const index in data) {
        let times = [];
        for (const timesIndex in data[index]) {
          // console.log(data[index], timesIndex);
          if (!data[index][timesIndex][0].available) {
            continue;
          }
          times.push(
            data[index][timesIndex][0] //@todo why [0]?
          );
        }
        if (times.length) {
          let eventRecord = {
            date: index,
            start: moment(index).toDate(),
            end: moment(index).toDate(),
            times,
            eventCount: times.length
          };
          availableDates[index] = eventRecord;
          //@todo component responsibility!!
          // this.calendarAttributes[0].dates.push(
          //   moment(index).toDate()
          // )
        }
      }
      return availableDates;
    },
    async setPractitioner(context, practitioner) {
      context.commit(
        'setBusyProcesses',
        {
          practitioner: true,
          service: true,
        }
      );
      console.log('setPractitioner Action', practitioner);
      context.commit('setPractitioner', practitioner);
      context.commit(
        'setBusyProcesses',
        {
          practitioner: false,
        }
      );
      await context.dispatch('fetchServices'); //@todo
      await context.dispatch('fetchEventDateTimes');
    },
    async fetchEventDateTimes(context) {
      // if (context.getters.isCurrentStep('eventCalendar')) {
      if (
        context.getters.practitioner
        && context.getters.service
      ) {
        context.commit(
          'setBusyProcesses',
          {
            eventCalendar: true,
          }
        );
        console.log(1001);
        await context.dispatch('fetchDatesForSelectedPractitioner');
        await context.dispatch('fetchDatesForAlternativePractitioners');
        console.log(1003);
      } else if (
        context.getters.service
        && context.getters.practitioners
      ) {
        console.log(1101);
        console.log('loadAllPotentialDatesForPractitioners', context.getters.loadAllPotentialDatesForPractitioners)
        if (context.getters.loadAllPotentialDatesForPractitioners === true) {
          console.log(1101.01);
          context.commit(
            'setBusyProcesses',
            {
              eventCalendar: true,
            }
          );
          await context.dispatch('fetchDatesForAlternativePractitioners');
          console.log(1101.03);
        }
        console.log(1103);
      }
    },
    async isFetchClinicsPractitioners(context) {
      const url = process.env.VUE_APP_API_URL_APPOINTMENTS + '/' +
        context.getters.clinicId +
        "/fetch-practitioners";

      await axios
        .get(url)
        .then(({ data }) => {
          let practitioners = {};
          Object.values(data).map(practitioner => {
            practitioners[practitioner.id] = practitioner;
            //@todo move to its rightful location
            context.dispatch('saveClinicDetails', practitioner);
          })
          console.log(104, Object.keys(practitioners).length);
          context.commit(
            'setPractitioners',
            practitioners
          );
          if (Object.values(practitioners).length === 1) {
            context.commit(
              'setPractitioner',
              Object.values(practitioners)[0]
            );
          }
        });
    },
    async fetchPackages(context) {
      context.commit(
        'setBusyProcesses',
        {
          package: true,
        }
      );
      await axios
        .get(
           process.env.VUE_APP_API_URL_APPOINTMENTS +
           "/fetch-clinic-packages/" +
          context.getters.clinicId
        )
        .then(({ data }) => {
          let packageIds = [];
          let packages = [];
          if (context.getters.practitioner) {
            for (const i in data) {
              for (const j in data[i].services) {
                if (data[i].services[j].practitioner_id == context.getters.practitioner.id) {
                  if (packageIds.indexOf(data[i].id) > -1) {
                    continue;
                  }
                  if (
                    context.getters.sortedFilteredPackageIds
                    && context.getters.sortedFilteredPackageIds.length
                    && context.getters.sortedFilteredPackageIds.indexOf(data[i].id) === -1
                  ) {
                    continue;
                  }
                  packageIds.push(data[i].id);
                  packages.push(data[i]);
                }
              }
            }
          } else {
            for (const i in data) {
              if (data[i].id === context.getters.defaultPackageId) {
                context.commit('setPackage', {
                 selected: data[i],
                 source: 'fetchPackages'
                });
                context.dispatch('setPackageServiceFilteredByPractitioner');
              }
            }
            packages = data;
          }
          context.commit('setPackages', packages);
          context.commit(
            'setBusyProcesses',
            {
              package: false,
            }
          );
        });
    },
    setPackageServiceFilteredByPractitioner(context) {
      if (
        context.state.packageSelected
        && !context.state.packageSelectedService
        && context.state.packageSelected.services
      ) {
        if (context.state.practitioner
          && context.state.practitioner.id
        ) {
          let practitionerService = context.state.packageSelected.services.filter(service => {
            return service.practitioner.id === context.state.practitioner.id;
          });
          if (practitionerService[0]) {
            context.commit('setPackageService', practitionerService[0]);
          }
          return true;
        } else {
          context.commit('setPackageService', context.state.packageSelected.services[0]);
        }
        return;
      }
    },
    async serviceUpdate(context, params) {
      context.commit(
        'setBusyProcesses',
        {
          practitioner: true,
          service: true
        }
      );
      context.commit(
        'setIsPractitionerService',
        params.isPractitionerService
      );
      context.commit(
        'setService',
        params.service
      );
      if (
        params.service
        && params.service.allow_standard === 1
        && params.service.allow_video === 0
      ) {
        console.log(`automatically selected service method 'In person'`)
        context.commit(
          'setServiceMethod',
          'Standard'
        );
      } else if (
        params.service
        && params.service.allow_standard === 0
        && params.service.allow_video === 1
      ) {
        console.log(`automatically selected service method 'Video'`)
        context.commit(
          'setServiceMethod',
          'Video'
        );
      }
      context.commit(
        'setBusyProcesses',
        {
          service: false
        }
      );
      console.log(4);
      if (context.getters.singleSeatedClinic === false
        && !params.practitioner
      ) {
        console.log(4.1);
        await context.dispatch('fetchPractitionersBySelectedService');
      } else if (params.practitioner) {
        context.commit('setPractitioner', params.practitioner);
      } else {
        console.log(5.8)
        await context.dispatch('fetchEventDateTimes');
      }
      console.log(5.7);
      await context.dispatch('fetchEventDateTimes');
      context.commit(
        'setBusyProcesses',
        {
          practitioner: false,
        }
      );
      console.log(6)
    },
    async fetchPractitionersBySelectedService (context) {
      console.log('fetchPractitionersBySelectedService')
      if (context.state.service === null) {
        console.log(5.5);
        // fetching all practitioners in clinic
        await context.dispatch('fetchPractitioners');
        return;
      }
      context.commit(
        'setBusyProcesses',
        {
          practitioner: true,
        }
      );
      return new Promise((resolve, reject) => {
        const url = process.env.VUE_APP_API_URL_APPOINTMENTS +
          "/fetch-practitoners/" +
          (context.getters.service.clinic_service_id ? context.getters.service.clinic_service_id : context.getters.service.id);
        axios
          .get(url)
          .then(({ data }) => {
            if (
              !data.pracServices
              || !data.pracServices.length
            ) {
              console.log(5.6);
              context.commit('setPractitioners', {});
              resolve();
              return;
            }
            let practitioners = {};
            let practitionerServices = {};
            if (
              context.getters.sortedFilteredPractitionerIds
              && context.getters.sortedFilteredPractitionerIds.length
            ) {
              practitioners = context.getters.sortedFilteredPractitionerIds.reduce((acc,curr)=> (acc[curr]='',acc),{});
            }
            let practitionerIds = [];
            for (const id in data.pracServices) {
              if (
                context.state.allowedPractitioners
                && context.state.allowedPractitioners.indexOf(data.pracServices[id].user_id) === -1
              ) {
                continue;
              }
              if (
                practitionerIds.indexOf(data.pracServices[id].user_id) > -1
                || data.pracServices[id].user.clinic_id != context.getters.clinicId
              ) {
                continue;
              }
              if (
                context.getters.sortedFilteredPractitionerIds
                && context.getters.sortedFilteredPractitionerIds.length
                && context.getters.sortedFilteredPractitionerIds.indexOf(data.pracServices[id].user_id) === -1
              ) {
                continue;
              }
              practitionerIds.push(data.pracServices[id].user_id);
              practitioners[data.pracServices[id].user_id] = data.pracServices[id].user;
              practitionerServices[data.pracServices[id].user.id] = data.pracServices[id].id
            }

            context.commit('setPractitioners', practitioners);
            // console.log(6666, practitionerServices)
            context.commit('setPractitionerServicePairs', practitionerServices);
            if (Object.values(practitioners).length === 1) {
              context.commit('setPractitioner', Object.values(practitioners)[0]);
            }
            console.log(5);
            context.commit(
              'setBusyProcesses',
              {
                practitioner: false,
              }
            );
            resolve();
          });
      });
    },
    async setBusy(context, bool) {
      if (context.getters.busy !== bool) {
        if (bool === false) {
          context.commit('setBusy', bool);
          // await new Promise((resolve, reject) => {
          //   setTimeout(() => {
          //     context.commit('setBusy', bool);
          //     console.log('setBusyTimeout');
          //     resolve();
          //   }, 300);
          // });
        } else {
          context.commit('setBusy', bool);
        }
      }
    },
    async fetchClinic (context, clinicId) {
      context.commit(
        'setBusyProcesses',
        {
          clinic: true,
        }
      );
      context.commit('setClinicId', clinicId);
      const url = process.env.VUE_APP_API_URL_APPOINTMENTS +
        "/fetch-clinic/" + clinicId;
      await axios
        .get(url)
        .then(({ data }) => {
          context.commit('setClinic', data.clinic);
          context.commit(
            'setBusyProcesses',
            {
              clinic: false,
            }
          );
        });
    },
    async fetchClinicBackgrounds (context) {
      context.commit(
        'setBusyProcesses',
        {
          clinic: true,
        }
      );
      context.commit('setClinicId', context.getters.clinicId);
      const url = process.env.VUE_APP_API_URL_APPOINTMENTS +
        "/fetch-clinic/background-urls/" + context.getters.clinicId;
      await axios
        .get(url)
        .then(({ data }) => {
          context.commit('setBackgroundUrl', data.backgroundUrl);
          context.commit('setHeaderBackgroundUrl', data.headerBackgroundUrl);
          context.commit(
            'setBusyProcesses',
            {
              clinic: false,
            }
          );
        });
    },
    async fetchPractitioners (context) {
      context.commit(
        'setBusyProcesses',
        {
          practitioner: true,
        }
      );
      const url = process.env.VUE_APP_API_URL_APPOINTMENTS  + '/' +
        context.getters.clinicId +
        "/fetch-practitioners";
      await axios
        .get(url)
        .then(({ data }) => {
          let practitioners = {};
          if (Object.keys(data).length === 1) {
            context.commit('setSingleSeatedClinic', true);
          }
          Object.values(data).map(practitioner => {
            if (
              context.state.allowedPractitioners
              && context.state.allowedPractitioners.indexOf(practitioner.id) === -1
            ) {
              return;
            }
            if (
              context.getters.sortedFilteredPractitionerIds
              && context.getters.sortedFilteredPractitionerIds.length
              && context.getters.sortedFilteredPractitionerIds.indexOf(practitioner.id) === -1
            ) {
              return;
            }
            // console.log(123123, context.getters.sortedFilteredPractitionerIds, practitioner.id, context.state.defaultPractitionerId);
            practitioners[practitioner.id] = practitioner;
            // console.log(1010, practitioners);
            if (practitioner.id === context.state.defaultPractitionerId) {
              context.commit('setPractitioner', practitioner);
            }
          });
          if (Object.keys(practitioners).length === 1
              && context.getters.practitioner === null
          ) {
            context.commit('setPractitioner', Object.values(practitioners)[0]);
            context.commit('setDefaultPractitionerId', Object.values(practitioners)[0].id);
          }
          context.commit('setPractitioners', practitioners);
          context.dispatch('setBusy', false);
          context.commit(
            'setBusyProcesses',
            {
              practitioner: false,
            }
          );
        });
    },
    async fetchServices(context) {
      context.commit(
        'setBusyProcesses',
        {
          service: true
        }
      );

      //clinic's services
      let url = process.env.VUE_APP_API_URL_APPOINTMENTS +
        "/fetch-clinic-services/" +
        context.getters.clinicId;

      //practitioner's services
      if (
        context.getters.practitioner
        && context.getters.practitioner.id
      ) {
        url = process.env.VUE_APP_API_URL_APPOINTMENTS +
          "/fetch-services/" +
          context.getters.practitioner.id;
      }
      // console.log('fetch services', url);
      await axios
        .get(url)
        .then(({ data }) => {
          let services = [];
          for (const i in data) {
            if (
              context.state.allowedServices
              && context.state.allowedServices.indexOf(data[i].id) === -1
            ) {
              continue;
            }
            if (data[i].id === context.state.defaultServiceId
              || ( data[i].clinic_service_id !== undefined
                && data[i].id !== context.state.defaultServiceId
                && data[i].clinic_service_id === context.state.defaultServiceId
              )
            ) {
              context.commit('setService', data[i]);
            }
            // console.log('serviceid', data[i].id, data[i])
            if (
              context.getters.sortedFilteredServiceIds
              && context.getters.sortedFilteredServiceIds.length
              && context.getters.sortedFilteredServiceIds.indexOf(data[i].id) === -1
              && context.getters.sortedFilteredServiceIds.indexOf(data[i].clinic_service_id) === -1
            ) {
              // console.log('skipped', data[i])
              continue;
            }
            services.push(data[i]);
          }
          console.log('services set', services)
          context.commit('setServices', services);
          console.log('service fetching ends');
          context.commit(
            'setBusyProcesses',
            {
              service: false
            }
          );
        });
    },
    async setPackageService (context) {
      await context.commit('setServices', context.getters.package.services);
      await context.commit('setPackageService', context.getters.package.services[0].practitioner_service);
      await context.dispatch('serviceUpdate', {
        service: context.getters.package.services[0].practitioner_service,
        practitioner: context.getters.package.services[0].practitioner,
        isPractitionerService: false
      });
    },
    async stepsIncrease (context) {
      const previousStep = context.getters.currentStep;
      let nextStep = 0;
      if (context.state.stepsNoDropDown !== null
      ) {
        if (previousStep === 1 && context.state.stepsNoDropDown[1.5]) {
          nextStep = 1.5;
        } else if (previousStep === 1.5) {
          nextStep = 2;
        } else if (previousStep === 2 && context.state.stepsNoDropDown[2.5]) {
          nextStep = 2.5;
        } else if (previousStep === 2.5) {
          nextStep = 3;
        } else {
          nextStep = previousStep + 1;
        }
        const currentMove =
          context.state.stepsNoDropDown[previousStep] +
          '_' +
          context.state.stepsNoDropDown[nextStep];
      }
      if (nextStep === 0) {
        throw new Error('invalid step')
      }
      context.commit('setCurrentStep', nextStep);
      //@todo dropdown update
    },
    setSortedFilteredServiceIds(context, sortedFilteredServiceIds) {
      if (!sortedFilteredServiceIds || sortedFilteredServiceIds.length === 0) {
        context.state.sortedFilteredServiceIds = null;
        return
      }
      context.state.sortedFilteredServiceIds = sortedFilteredServiceIds
        .split(',')
        .map(strId => parseInt(strId));
    },
    setSortedFilteredPackageIds(context, sortedFilteredPackageIds) {
      if (!sortedFilteredPackageIds || sortedFilteredPackageIds.length === 0) {
        context.state.sortedFilteredPackageIds = null;
        return
      }
      context.state.sortedFilteredPackageIds = sortedFilteredPackageIds
        .split(',')
        .map(strId => parseInt(strId));
    },
    setSortedFilteredTncIds(context, sortedFilteredTncIds) {
      if (!sortedFilteredTncIds || sortedFilteredTncIds.length === 0) {
        context.state.sortedFilteredTncIds = null;
        return
      }
      context.state.sortedFilteredTncIds = sortedFilteredTncIds
        .split(',')
        .map(strId => parseInt(strId));
    },
    setSortedFilteredPractitionerIds(context, sortedFilteredPractitionerIds) {
      if (!sortedFilteredPractitionerIds || sortedFilteredPractitionerIds.length === 0) {
        context.state.sortedFilteredPractitionerIds = null;
        return;
      }
      context.state.sortedFilteredPractitionerIds = sortedFilteredPractitionerIds
        .split(',')
        .map(strId => parseInt(strId));
    },
    async stepsDecrease (context) {
      const previousStep = context.getters.currentStep;
      let nextStep = 0;
      if (previousStep === 2 && context.state.stepsNoDropDown[1.5]) {
        nextStep = 1.5;
      } else if (previousStep === 1.5) {
        nextStep = 1;
      } else if (previousStep === 1 && context.state.clientDetailsCaptured === 'first') {
        console.log('yes')
        nextStep = 0;
      } else {
        nextStep = previousStep - 1;
      }

      const currentMove =
        context.state.stepsNoDropDown[previousStep] +
        '_' +
        context.state.stepsNoDropDown[nextStep];

      console.log('current back move', currentMove)

      //reset and load
      switch (currentMove) {
        case 'service_undefined':
          context.commit('setService', null);
          context.commit('setServiceMethod', null);
          context.commit('setPackage', null);
          context.commit('setPackageMethod', null);
          context.commit('setPackageService', null);
          await context.dispatch('fetchPractitioners');
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
        break;
        case 'practitioner_clientDetails':
          context.commit('setBusyMain', true);
          context.commit('setPractitioner', null);
          context.commit('setClientDetailsValidated', null);
          await context.dispatch('fetchPractitioners');
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
          context.commit('setBusyMain', false);
        break;
        case 'service_clientDetails':
          context.commit('setBusyMain', true);
          context.commit('setService', null);
          context.commit('setServiceMethod', null);
          context.commit('setPackage', null);
          context.commit('setPackageMethod', null);
          context.commit('setPackageService', null);
          context.commit('setClientDetailsValidated', null);
          await context.dispatch('fetchPractitioners');
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
          context.commit('setBusyMain', false);
        break;
        case 'clientDetails_eventCalendar':
        break;
        case 'practitioner_undefined':
          context.commit('setPractitioner', null);
          await context.dispatch('fetchPractitioners');
          await context.dispatch('fetchServices');
          await context.dispatch('fetchPackages');
        break;
        case 'eventCalendar_practitioner':
          if (context.getters.package && context.getters.package.id) {
            context.commit('setBusyMain', true);
            context.commit('setPractitioner', null);
            context.commit('setService', null);
            context.commit('setServiceMethod', null);
            context.commit('setPackage', null);
            context.commit('setPackageMethod', null);
            context.commit('setPackageService', null);
            context.commit('setServiceMethod', null);
            context.commit('setAvailableDates', {});
            context.commit('setAlternativeDates', {});
            await context.dispatch('fetchServices');
            // await context.dispatch('fetchPackages');
            context.commit('setCurrentStep', 1);
            context.commit('setBusyMain', false);
            return;
          }
          context.commit('setPractitioner', null);
          context.commit('setAvailableDates', {});
          context.commit('setAlternativeDates', {});
        break;
        case 'eventCalendar_service':
          context.commit('setService', null);
          context.commit('setServiceMethod', null);
          context.commit('setPackage', null);
          context.commit('setPackageMethod', null);
          context.commit('setPackageService', null);

          context.commit('setAvailableDates', {});
          context.commit('setAlternativeDates', {});

          if (Object.values(context.state.stepsNoDropDown).join(',') === 'service,eventCalendar,clientDetails'
            && context.getters.practitioner && context.getters.practitioner.id
          ) {
            context.commit('setPractitioner', null);
            await context.dispatch('fetchServices');
            await context.dispatch('fetchPackages');
          }
        break;
        default:
          throw new Error('unknown current move')
      }
      if (context.state.currentStep > 1) {
        console.log('current page to ', context.state.currentStep - 1, 'vs', nextStep)
        context.commit('setCurrentStep', context.state.currentStep - 1);
      } else if (previousStep === 1 && context.state.clientDetailsCaptured === 'first') {
        console.log('current page to ', 0)
        context.commit('setCurrentStep', 0);
      }
    }
  },
  mutations: {
    setLoadAllPotentialDatesForPractitioners(state, enabled) {
      state.loadAllPotentialDatesForPractitioners = enabled;
    },
    setPractitionerServicePairs(state, pbj) {
      state.practitionerServicePairs = pbj;
    },
    setTncToValidate(state, consent) {
      state.tncToValidate[consent.id] = consent;
    },
    setHeaderBackgroundUrl(state, headerBackgroundUrl) {
      state.styles.header.image = headerBackgroundUrl;
      state.headerBackgroundUrl = headerBackgroundUrl;
    },
    setBackgroundUrl(state, backgroundUrl) {
      state.styles.background.image = backgroundUrl;
      state.backgroundUrl = backgroundUrl;
    },
    setThankyouSuccessMessage(state, thankyouSuccessMessage) {
      state.thankyouSuccessMessage = thankyouSuccessMessage;
    },
    setThankyouFailedMessage(state, thankyouFailedMessage) {
      state.thankyouFailedMessage = thankyouFailedMessage;
    },
    setPaymentAdditionalInformation(state, paymentAdditionalInformation) {
      state.paymentAdditionalInformation = paymentAdditionalInformation;
    },
    setPaymentFreeService(state, paymentFreeService) {
      state.paymentFreeService = paymentFreeService;
    },
    setTerms (state, terms) {
      state.terms = terms;
    },
    setConsultation (state, consultation) {
      state.consultation = consultation;
    },
    setPhonePrefix (state, prefix) {
      state.phonePrefix = prefix;
    },
    setPhoneWithoutPrefix (state, phoneNumber) {
      state.clientDetails.phone = phoneNumber;
    },
    setClientId (state, clientId) {
      state.client = clientId;
    },
    setTncSubmitStatus (state, status) {
      state.tncSubmitStatus = status;
    },
    setClientDetailsSubmitStatus (state, status) {
      state.clientDetailsSubmitStatus = status;
    },
    setStyles (state, customStyles) {
      if (!Object.keys(customStyles).length) {
        return;
      }
      state.styles = {
        main: {
          main: customStyles.generic.items.mainColor.value,
          warning: customStyles.generic.items.warningColor.value,
          error: customStyles.generic.items.errorColor.value,
          fontColor: customStyles.button.items.fontColor.value,
        },
        button: {
          backgroundColor: customStyles.button.items.backgroundColor.value,
          activeColor: customStyles.button.items.activeColor.value,
          disabledColor: customStyles.button.items.disabledColor.value,
          fontColor: customStyles.button.items.fontColor.value,
        },
        calendar: {
          activeDayColor: customStyles.calendar.items.activeDayColor.value,
        },
        dropDown: {
          borderColor: customStyles.dropDown.items.borderColor.value,
          textColor: customStyles.dropDown.items.textColor.value,
        },
        list: {
          // borderColor: customStyles.list.items.borderColor.value,
          // selectedBorderColor: customStyles.list.items.selectedBorderColor.value,
          textColor: customStyles.list.items.textColor.value,
        },
        background: {
          image: customStyles.background.items.image.value,
          color: customStyles.background.items.color.value,
        },
        header: {
          image: customStyles.header.items.image.value,
          color: customStyles.header.items.color.value,
        },
      };
    },
    setBusyMain (state, bool) {
      state.busyMain = bool;
    },
    setScenarioText (state, scenarioText) {
      state.scenarioText = scenarioText;
    },
    setStepsNoDropDown (state, obj) {
      state.stepsNoDropDown = obj;
    },
    setClinic (state, clinic) {
      state.clinic = clinic;
    },
    setAlternativeDates (state, params) {
      state.alternativeDates = params;
      console.log('setAlternativeDates', state.alternativeDates);
    },
    setAvailableDates (state, params) {
      state.availableDates = params;
      console.log('setAvailableDates', state.availableDates);
    },
    setBusyProcesses (state, params) {
      if (params.practitioner !== undefined) {
        state.busyProcesses.practitioner = params.practitioner;
      }
      if (params.service !== undefined) {
        state.busyProcesses.service = params.service;
      }
      if (params.package !== undefined) {
        state.busyProcesses.package = params.package;
      }
      if (params.eventCalendar !== undefined) {
        state.busyProcesses.eventCalendar = params.eventCalendar;
      }
    },
    setDropDownModalVisibility (state, params) {
      state.dropDownModalVisibility[params.type] = params.bool
    },
    setSingleSeatedClinic(state, bool) {
      state.singleSeatedClinic = bool;
    },
    setSinglePractitioner(state, bool) {
      state.singlePractitioner = bool;
    },
    setDefaultPractitionerId (state, id) {
      console.log('defaultPractitionerId', id)
      state.defaultPractitionerId = id;
    },
    setDefaultPackageId(state, id) {
      state.defaultPackageId = id;
    },
    setDefaultServiceId(state, id) {
      state.defaultServiceId = id;
    },
    setSkipPractitioners(state, bool) {
      state.skipPractitioners = bool;
    },
    setServiceFirst(state, bool) {
      state.serviceFirst = bool;
    },
    setBusy(state, bool) {
      state.busy = bool;
    },
    setScenario(state, scenario) {
      state.scenario = scenario
    },
    setCurrentStep(state, step) {
      state.currentStep = step
    },
    setClinicId(state, id) {
      state.clinicId = id;
      state.practitioner = null;
      state.practitioners = null;
      state.services = null;
      state.service = null;
      state.packageSelected = null;
      state.packageSelectedService = null;
    },
    setClientDetailsCaptured(state, clientDetailsCaptured) {
      if (['first', 'last'].indexOf(clientDetailsCaptured) === -1
        || clientDetailsCaptured === undefined
      ) {
        state.clientDetailsCaptured = 'last';
        return;
      }
      state.clientDetailsCaptured = clientDetailsCaptured;
    },
    setHeaderOn(state, headerOn) {
      state.headerOn = (!headerOn || headerOn === undefined)
        ? false
        : true;
    },
    setClinicLogoAlign(state, clinicLogoAlign) {
      if (['left', 'center', 'right'].indexOf(clinicLogoAlign) === -1) {
        state.clinicLogoAlign = 'right'
        return;
      }
      state.clinicLogoAlign = clinicLogoAlign;
    },
    setClinicLogoVisible(state, clinicLogoVisible) {
      state.clinicLogoVisible = (!clinicLogoVisible || clinicLogoVisible === undefined)
        ? false
        : true;
    },
    setClinicNameVisible(state, clinicNameVisible) {
      state.clinicNameVisible = (!clinicNameVisible || clinicNameVisible === undefined)
        ? false
        : true;
    },
    setOfferingPhoto(state, offeringPhoto) {
      state.offeringPhoto = (!offeringPhoto || offeringPhoto === undefined)
        ? false
        : true;
    },
    setOfferingDescription(state, offeringDescription) {
      state.offeringDescription = (!offeringDescription || offeringDescription == "false")
        ? false
        : true;
    },
    setPractitionerPhoto(state, practitionerPhoto) {
      state.practitionerPhoto = (!practitionerPhoto || practitionerPhoto == "false")
        ? false
        : true;
    },
    setPractitionerBio(state, practitionerBio) {
      state.practitionerBio = (!practitionerBio || practitionerBio == "false")
        ? false
        : true;
    },
    setDefaultParams(state, params) {
      state.clinicId = params.clinicId;
      state.allowedPractitioners = params.allowedPractitioners
        ? params.allowedPractitioners.split(',').map(el => parseInt(el))
        : null;
      state.allowedServices = params.allowedServices
        ? params.allowedServices.split(',').map(el => parseInt(el))
        : null;
      state.defaultPractitionerId = params.practitionerId ? params.practitionerId : null;
      state.defaultServiceId = params.serviceId ? params.serviceId : null;
      state.defaultPackageId = params.packageId ? params.packageId : null;
      state.redirect = params.redirect ? params.redirect : '';
      state.singlePractitionerTileVisible =
        params.singlePractitionerTileVisible
        ? params.singlePractitionerTileVisible == "false" ? false : true
        : null;
      state.serviceFirst =
        (!params.serviceFirst || params.serviceFirst == "false")
        ? false
        : true;
      state.skipPractitioners =
        (!params.skipPractitioners || params.skipPractitioners == "false")
        ? false
        : true;

      if (state.serviceFirst === true) {
        state.orderOfDataCollecting = ['service', 'practitioner'];
      }
    },
    setPractitioner(state, practitioner) {
      state.practitioner = practitioner;
      state.packageSelected = null;
      // if (state.lastSelectedService === 'practitioner') {
      //   state.service = null;
      // }
      state.date = null;
      state.time = null;

      if (practitioner && practitioner.clinic && practitioner.clinic.id) {
        state.clinic = practitioner.clinic;
      }

      if (state.serviceFirst === true) {
        if ((
            state.practitioner
            && state.service
          )
          && state.currentStep === 1
        ) {
          console.log('setCurrentStepPr', 2)
          state.currentStep = 2;
        }
      }
    },
    setPractitioners(state, practitioners) {
      state.practitioners = practitioners;
      if (!state.practitioner) {
        return;
      }
      if (Object.keys(practitioners).length === 1) {
        state.singlePractitioner = true;
      } else {
        state.singlePractitioner = false;
      }
    },
    setPackages(state, packages) {
      const sortedFilteredPackageIds = state.sortedFilteredPackageIds;
      if (
        sortedFilteredPackageIds
        && sortedFilteredPackageIds.length
      ) {
        packages = packages.filter((service) =>
          sortedFilteredPackageIds.indexOf(service.id) !== -1
          || sortedFilteredPackageIds.indexOf(service.clinic_service_id) !== -1);

        packages = packages.sort((first, second) => {
          let targetId = 'id';
          if (sortedFilteredPackageIds.indexOf(second[targetId]) > sortedFilteredPackageIds.indexOf(first[targetId])) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      state.packages = packages;
    },
    setServices(state, services) {
      const sortedFilteredServiceIds = state.sortedFilteredServiceIds;
      if (
        sortedFilteredServiceIds
        && sortedFilteredServiceIds.length
      ) {
        services = services.filter((service) =>
          sortedFilteredServiceIds.indexOf(service.id) !== -1
          || sortedFilteredServiceIds.indexOf(service.clinic_service_id) !== -1);

        services = services.sort((first, second) => {
          let targetId = 'id';
          if (first.clinic_service_id && first.clinic_service_id !== null) {
            targetId = 'clinic_service_id';
          }
          if (sortedFilteredServiceIds.indexOf(second[targetId]) > sortedFilteredServiceIds.indexOf(first[targetId])) {
            return -1;
          } else {
            return 1;
          }
        });
      }

      //skip if no service method allowed
      services = services.filter((s) => {
        let target = s.service ? s.service : s;
        if (
          target.allow_video === undefined
          && s.practitioner_service.service
        ) {
          target = s.practitioner_service.service;
        }

        if (
          !target.allow_standard
          && !target.allow_video
        ) {
          console.log(`skipping service '${s.name}'' as no service method allowed on it`)
          return false;
        }
        s.allow_standard = target.allow_standard ? 1 : 0;
        s.allow_video = target.allow_video ? 1 : 0;
        return true;
      });

      if (services.length
        && services[0].user_id
        && services[0].service
      ) {
        let practitionerServicePairs = {};
        services.map((service) => {
          practitionerServicePairs[service.user_id] = service.id
        })
        state.practitionerServicePairs = practitionerServicePairs;
        console.log('practitionerServicePairs', practitionerServicePairs)
      }
      state.services = services;
      if (!state.service) {
        return;
      }
      if (Object.keys(state.services).length === 1) {
        state.singleService = true;
      } else {
        state.singleService = false;
      }
    },
    setService(state, service) {
      state.service = service;
      // state.packageSelected = null;
      // state.packageSelectedService = null;
      if (service === null) {
        state.serviceMethod = null;
      }
      state.date = null;
      state.time = null;

      if (state.serviceFirst === false) {
        if ((
            state.practitioner
            && state.service
          )
          && state.currentStep === 1
        ) {
          console.log('setCurrentStepSr', 2)
          state.currentStep = 2;
          return;
        }
      }
      if (
        state.serviceFirst === true
        && (state.service && state.service.id)
        && state.currentStep === 1
        && state.skipPractitioners === true
      ) {
        console.log('setCurrentStepSr', 2)
        state.currentStep = 2;
        return;
      }
      if (
        (state.defaultServiceId || state.skipPractitioners === true)
        && (state.service && state.service.id)
        && state.currentStep === 1
      ) {
          console.log('setCurrentStepSr', 2)
          state.currentStep = 2;
          return;
      }
    },
    setServiceMethod(state, serviceMethod) {
      console.log('set service method')
      state.serviceMethod = serviceMethod;
    },
    setPackage(state, p) {
      state.service = null;
      state.date = null;
      state.time = null;
      if (p === null) {
        state.package = null;
        state.packageSelected = null;
        state.serviceMethod = null;
        state.lastSelectedService = 'clinic';
        return;
      }
      state.package = p.selected;
      state.packageSelected = p.selected;
    },
    setPackageService(state, service) {
      if (service !== null
          && service.practitioner
      ) {
        state.practitioner = service.practitioner;
      }
      state.service = service;
      state.lastSelectedService = 'none';
      state.packageSelectedService = service;

      if (
        state.practitioner
        && state.service
        && state.currentStep === 1
      ) {

        console.log('setCurrentStepPS', 2, state.practitioner, state.service)
        state.currentStep = 2;
      }
    },
    setPackageMethod(state, packageMethod) {
      state.packageMethod = packageMethod;
    },
    setIsPractitionerService(state, isPractitionerService) {
      state.lastSelectedService = isPractitionerService ? 'practitioner' : 'clinic'; //@todo contants
    },
    setPractitionersByService(state, practitioners) {
      if (practitioners.length === 0) {
        return;
      }
      state.singlePractitioner = practitioners.length > 0 ? false : true;

      let sortedPractitioners = practitioners;
      const sortedFilteredPractitionerIds = state.sortedFilteredPractitionerIds;
      if (
        sortedFilteredPractitionerIds
        && sortedFilteredPractitionerIds.length
      ) {
        if (sortedFilteredPractitionerIds.length === practitioners.length) {
          throw new Error(`package array vs filtered sorted length diff`)
        }
        sortedPractitioners = [];
        sortedFilteredPractitionerIds.forEach((id, indexId) => {
          practitioners.forEach((p, index) => {
            if (p.id === id) {
              sortedPractitioners.push(p);
            }
          });
        });
      }
      state.practitioners = sortedPractitioners;
    },
    setDateTime(state, dateAndTime) {
      state.date = dateAndTime.date;
      state.time = dateAndTime.time;
    },
    setClientDetailsValidated(state, isValidated) {
      state.clientDetailsValidated = isValidated;
    },
    setTncValidated(state, tncConsent) {
      state.tncValidated[tncConsent.id] = tncConsent.state;
    },
    setClientDetails(state, clientDetails) {
      state.clientDetails = clientDetails.client;
    }
  },
  strict: debug,
});

<template>
  <div>
    <div class="row">
      <clinic-header />
    </div>
    <div class="row text-left thankYou">
      <div class="col-lg-4 col-md-4 col-sm-12 summaryTileFrame" v-if="showSummaryTile()">
        <summary-tile />
      </div>
      <div class="col thankYouDetails">
        <div class="card border-0">
          <div class="card-body">
            <div class="row">
              <!--  Paid or Free Service -->
              <div class="col-12 percentagePaddingLocalRight" v-if="paid || $store.getters.consultation.price === 0">
                <span :style="mixinButtonColorsSvgHover()"><font-awesome-icon :icon="['fas', 'check-square']"/></span>
                <h3>Thank you!</h3>
                <p>You have successfully booked the service!</p>
                <long-text-show-more
                  :text="$store.getters.paymentFreeService"
                  limit="200"
                />
                <long-text-show-more
                  :text="$store.getters.thankyouSuccessMessage"
                  limit="200"
                />
              </div>
              <!-- Not Paid but has a price-->
              <!-- According to Elizabeth Andrews in the new version we should never hit this below -->
              <div class="col-12 percentagePaddingLocalRight" v-if="!paid && $store.getters.consultation.price > 0">
                <span :style="mixinButtonColorsSvgHoverWarning()"><font-awesome-icon :icon="['fas', 'exclamation-circle']"/></span>
                <h3>Payment failed!</h3>
                <p>You haven't booked the service yet!</p>
                <long-text-show-more
                  :text="$store.getters.thankyouFailedMessage"
                  limit="100"
                />

                <p class="alert alert-warning mb-4">
                  Your payment has not been received, please try again
                </p>
              </div>
              <div class="col-12 mb-4 attemptAgainButtonFrame" v-if="!paid && $store.getters.consultation.price > 0">
                  <button
                    class="btn myButton float-right"
                    :style="buttonHoverHandler"
                    @mouseover="buttonHoverActive()"
                    @mouseout="buttonHoverInactive()"
                    @click="tryPaymentAgain"
                  >
                    Attempt payment again
                  </button>
                </div>
            </div>
            <div class="row">
              <div class="col">
                <p><img :src="$store.getters.clinic.logo" width="50" /></p>
                <h5>{{$store.getters.clinic.name}}</h5>
                <p>{{$store.getters.clinic.email_address}}</p>
                <p>{{$store.getters.clinic.address}}</p>
                <p>{{$store.getters.clinic.address_1}}</p>
                <p>{{$store.getters.clinic.address_2}}</p>
                <p>{{$store.getters.clinic.address_city}}</p>
                <p>{{$store.getters.clinic.address_county}}</p>
                <p>{{$store.getters.clinic.address_post_code}}</p>
                <p>{{$store.getters.clinic.address_town}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LongTextShowMore from "./partials/LongTextShowMore";
import colorMixin from "../mixins/colorMixin";
import SummaryTile from "./partials/SummaryTile";
import ClinicHeader from "./ClinicHeader";
import axios from "axios";

export default {
  mixins: [colorMixin],
  components: {
    SummaryTile,
    ClinicHeader,
    LongTextShowMore,
  },
  data() {
    return {
      paid: false,
      buttonHover: false,
    };
  },
  computed: {
    buttonHoverHandler() {
      if (this.buttonHover === true) {
        return this.mixinButtonColorsHover();
      }
      return this.mixinNextButtonColors();
    },
  },
  methods: {
    buttonHoverActive() {
      this.buttonHover = true;
    },
    buttonHoverInactive() {
      this.buttonHover = false;
    },
    showSummaryTile() {
      if (this.$store.getters.practitioner || this.$store.getters.service) {
        return true;
      }
      return false;
    },
    confirmConsultation() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/front-end/appointments/confirm/",
          {
            ...this.$store.getters.consultation
          }
        )
        .then(({ data }) => {
          console.log(data);
        });
    },
    tryPaymentAgain() {
      window.location.href = window.location.href =
        process.env.VUE_APP_FRONTEND_URL +
        "/consultations/payment/" +
         this.$store.getters.consultation.id +
        "?redirect=" +
        encodeURIComponent(window.location);
    },
  },
  mounted() {
    if (
      this.$store.getters.consultation.paymentStatus == "paid"
      || this.$store.getters.consultation.paymentStatus == "partial"
    ) {
      this.paid = true;
      this.confirmConsultation();
    }
  },
};
</script>

<style scoped>
@media (max-width: 752px) {
  .summaryTileFrame {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  div.phoneNumber {
    padding-left: 15px;
  }
  div.percentagePaddingLocalOverride {
    padding-left: 10%;
    padding-right: 10%;
  }

}
.attemptAgainButtonFrame {
  display: flex;
  justify-content: center;
  align-items: center;
}
.phoneNumber {
  padding-left: 0;
}
</style>
<style>
.myButton {
  border: 1px solid #ddd;
}
div.thankYouDetails span.titleChopped {
  margin: 1rem 0 !important;
}
p {
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  div.percentagePaddingLocalOverride {
    padding-left: 6%;
    padding-right: 6%;
  }
  div.formFrame {
    padding-bottom: 0rem !important;
  }

  .summaryTileFrame .summaryTile > div {
    padding-bottom: 0 !important;
  }
}
.percentagePaddingLocalOverride {
  padding-left: 20%;
  padding-right: 20%;
}
.percentagePaddingLocalLeft {
  padding-left: 15%;
  padding-right: 15%;
}
.percentagePaddingLocalRight {
  padding-left: 15%;
  padding-right: 15%;
}
.noPadding > div {
  padding-left: 0;
  padding-right: 0;
}
.summaryTileFrame {
  border-right: 1px solid #ddd;
}
.formFrame {
  padding-bottom: 2rem !important;
}
.tncFrame {
  padding-top: 3rem !important;
  border-top: 1px solid #ddd;
}
.percentagePadding.showSummaryTile {
  padding: 0 20%;
}
div.thankYouDetails svg {
  width: 50px;
}
div.thankYouDetails span {
  margin: 2rem 0;
  display: inline-block;
}
div.thankYouDetails {
  text-align: center;
  font-size: 0.85rem;
}
div.thankYou {
  border: 1px solid #ddd;
}
div.thankYou > div {
  padding-left: 0;
  padding-right: 0;
}
</style>
<template>
  <div class="row">
    <div class="col hoveringSpinner" v-if="$store.getters.busy">
      <busy></busy>
    </div>
    <clinic-header v-if="$store.state.headerOn" />
    <normal-steps/>
    <div class="col-md-12 pl-0 pr-0">
      <button
        v-if="showNextButton"
        class="btn prevNext btn-primary mt-4 float-right"
        :class="showNextButton ? '' : 'disabled'"
        :style="nextButtonHoverHandler"
        @mouseover="nextButtonHoverActive()"
        @mouseout="nextButtonHoverInactive()"
        @click="goNext"
      >
        NEXT
      </button>
      <div
        class="float-right mt-3 selectFromAbove"
        style="line-height: 50px;"
        v-else
      >
        Please select an option from above
      </div>
      <button
        v-if="isGoBackVisible()"
        class="btn prevNext btn-primary mt-4"
        :style="backButtonHoverHandler"
        @mouseover="backButtonHoverActive()"
        @mouseout="backButtonHoverInactive()"
        @click="goBack"
      >
        BACK
      </button>
    </div>
  </div>
</template>

<script>

import Busy from "./partials/Busy";
import NormalSteps from "./NormalSteps";
import colorMixin from "../mixins/colorMixin";
import ClinicHeader from './ClinicHeader';

export default {
  mixins: [colorMixin],
  data() {
    return {
      nextBtnHover: null,
      backBtnHover: null,
    };
  },
  computed:{
    nextButtonHoverHandler() {
      if (!this.showNextButton) {
        return this.mixinButtonDisabledColors()
      }
      if (this.nextBtnHover === true) {
        return this.mixinButtonColorsHover();
      }
      if (this.$store.getters.isCurrentStep('eventCalendar')) {
        return this.mixinNextButtonColorsOutlineMain();
      } else {
        return this.mixinNextButtonColors();
      }
    },
    backButtonHoverHandler() {
      if (this.backBtnHover === true) {
        return this.mixinBackHoverButtonColors();
      }
      return this.mixinBackButtonColors();
    },
    showNextButton() {
      let ret = (
        (
          (this.$store.state.practitioner && this.$store.state.practitioner.id)
          || (this.$store.state.service && this.$store.state.service.id)
          || (this.$store.state.packageSelected && this.$store.state.packageSelected.id)
        )
        && (this.$store.state.currentStep < this.$store.state.steps.packageConfirm)
        && (
            (
              this.$store.state.currentStep == this.$store.state.steps.eventCalendar
              && this.$store.state.date
              && this.$store.state.time
              && this.$store.state.serviceMethod
            )
            || this.$store.state.currentStep != this.$store.state.steps.eventCalendar
        )
      );
      if (this.$store.state.serviceFirst === false
        && this.$store.state.practitioner
        && !this.$store.state.serviceMethod
        && !this.$store.state.packageSelected
      ) {
        ret = false;
      }
      if (this.$store.state.singleSeatedClinic === true
        && this.$store.state.currentStep === 1
        && (!this.$store.state.service && !this.$store.state.packageSelected)
      ) {
        ret = false;
      }
      if (this.$store.state.singleSeatedClinic === false
        && this.$store.state.currentStep === 1
        && (
          this.$store.state.service
          || (this.$store.getters.package && !this.$store.getters.packageService)
        )
      ) {
        ret = true;
      }

      if (this.$store.getters.isCurrentStep('clientDetails')) {
        ret = true;
      }
      return ret;
    },
  },
  watch: {
    lastSelectedService(newVal, oldVal) {
      if (newVal !== oldVal && oldVal !== null && newVal !== null) {
        this.isServiceSourceChanged = true;
      } else {
        this.isServiceSourceChanged = false;
      }
    }
  },
  methods: {
    nextButtonHoverActive() {
      this.nextBtnHover = true;
    },
    nextButtonHoverInactive() {
      this.nextBtnHover = null;
    },
    backButtonHoverActive() {
      this.backBtnHover = true;
    },
    backButtonHoverInactive() {
      this.backBtnHover = null;
    },
    async goNext() {
      if (!this.showNextButton) {
        return;
      }
      const lastStep = (this.$store.state && this.$store.state.stepsNoDropDown && this.$store.state.stepsNoDropDown[3])
        ? 3 : 2
      if (this.$store.getters.isCurrentStep('clientDetails')) {
        console.log('saving client details and tnc')
        const res = await this.submitClientDetailsAndTnCPage();
        if (res === false) {
          this.$store.commit('setClientDetailsSubmitStatus', 'ERROR');
          console.log('client details save failed')
          return;
        }
        console.log('client details saved')
      }
      if (this.$store.getters.currentStep === lastStep) {
        await this.$store.dispatch('bookAppointment');
        return;
      }
      this.$store.dispatch('stepsIncrease');
    },
    async submitClientDetailsAndTnCPage() {
      this.busy = true;
      let ret = false;
      const consentValidated = this.tncValidatedSum();
      this.$store.commit('setTncSubmitStatus', null);
      if (!this.$store.getters.clientDetailsValidated
        || !consentValidated
      ) {
        console.log('client details and or tnc are not validated')
        if (!this.$store.getters.clientDetailsValidated) {
          this.$store.commit('setClientDetailsSubmitStatus', 'ERROR');
        }
        if (!consentValidated) {
          this.$store.commit('setTncSubmitStatus', 'ERROR');
        }
      } else {
        await this.$store.dispatch('submitClientDetails');
        if (this.$store.getters.clientDetailsSubmitStatus === 'OK') {
          ret = true;
        }
      }
      this.busy = false;
      return ret;
    },
    tncValidatedSum() {
      // console.log(1, Object.values(this.$store.getters.tncValidated))
      return Object.values(this.$store.getters.tncValidated).reduce((consent, sum) => consent && sum, true)
    },
    goBack() {
      this.$store.dispatch('stepsDecrease');
    },
    isGoBackVisible() {
      if (
        this.$store.state.currentStep === 1
        && this.$store.state.singleSeatedClinic === true
      ) {
        return false;
      }
      if (
        this.$store.state.currentStep === 1
        && this.$store.state.defaultPractitionerId !== null
      ) {
        return false;
      }
      if (
        this.$store.state.currentStep === 2
        && this.$store.state.defaultServiceId !== null
        && this.$store.state.skipPractitioners === true
      ) {
        return false;
      }
      if (
        (
          this.$store.state.practitioner
          || this.$store.state.service
          || this.$store.state.packageSelected
        )
        && this.$store.state.currentStep >= 1
      ) {
        return true;
      }

      if (
        this.$store.getters.clientDetailsCaptured === 'first'
        && this.$store.getters.currentStep !== 0 //0 = cllientDetails
      ) {
        return true;
      }
      return false;
    },
  },
  components: {
    Busy,
    NormalSteps,
    ClinicHeader,
  },
};
</script>
<style>
div.selectFromAbove {
  font-size: 0.85rem
}
button.prevNext.btn:focus,
button.prevNext.btn:active {
  box-shadow: none !important;
  border: 1px solid #ddd !important;
}
button.prevNext.btn {
  font-weight: bold;
  text-transform: capitalize !important;
  padding: 0.5em 2em;
  font-size: 100%;
  border: 1px solid #ddd;
}
button.prevNext.btn.disabled {
  background-color: #bbb !important;
  color: white !important;
  border-color: #cdcdcd;
  cursor: not-allowed;
}
.hoveringSpinner > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.hoveringSpinner {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: rgb(255, 255, 255, 0.8);
}
</style>

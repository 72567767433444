<template>
  <div
    v-bind:class="['modal', 'fade', modalVisibilityClass]"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    v-if="!$store.getters.busy"
  >
    <div class="modal-dialog modal-lg modal-xl shadow mb-5 bg-white rounded" role="document">
      <div class="modal-content">
        <div class="modal-header myModalHeader">
          <h6 v-if="!customTitle" style="margin-bottom: 0;"><strong>{{type === 'service' ? 'Services' : 'Practitioners'}}</strong></h6>
          <h6 v-else style="margin-bottom: 0;"><strong>{{customTitle}}</strong></h6>
          <button @click="closeDropdownModal()" type="button" class="close close-modal-dropdown" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <list
            v-if="type === 'service'"
            :data="data"
            :descriptionContentEnabled="$store.getters.offeringDescriptionEnabled"
            listed="services"
            :type="this.$store.getters.practitioner ? 'clinic' : 'practitioner'"
            @selected="selectService"
            :image-field="$store.state.offeringPhoto ? 'cover_image' : false"
            :additionalFields="['duration', 'price_display']"
            :colsInOneRow="4"
            :selectedId="selectedData ? selectedData.id : undefined"
            :detailsField="'description'"
            :additionalFields2="['In person', 'Video', 'Phone']"
            :additionalFieldsPreFix="['']"
            :additionalFieldsPostFix="[' mins |', '']"
            button-label="Select"
          />
          <list
            v-else-if="type === 'practitioner' && listType === undefined"
            :data="data"
            :descriptionContentEnabled="$store.getters.practitionerBioEnabled"
            listed="practitioners"
            :colsInOneRow="4"
            :selectedId="selectedData && selectedData.id ? selectedData.id : ''"
            :detailsField="'community_description'"
            :type="this.$store.getters.practitioner ? 'clinic' : 'practitioner'"
            @selected="selectPractitioner"
            :image-field="$store.state.practitionerPhoto ? 'profile_pic_url' : false"
            button-label="Select"
          />
          <listWithTime
            v-else-if="type === 'practitioner' && listType === 'listWithTime'"
            :data="data"
            :descriptionContentEnabled="$store.getters.practitionerBioEnabled"
            listed="practitioners"
            :dateBasedPractitionerTimes="dateBasedPractitionerTimes"
            :colsInOneRow="4"
            :selectedTime="selectedTime"
            :selectedId="selectedData && selectedData.id ? selectedData.id : ''"
            :detailsField="'community_description'"
            :type="this.$store.getters.practitioner ? 'clinic' : 'practitioner'"
            @timeButtonClick="timeButtonClick"
            :image-field="$store.state.practitionerPhoto ? 'profile_pic_url' : false"
            button-label="Select"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import List from "./List";
import ListWithTime from "./ListWithTime";
import colorMixin from "../../mixins/colorMixin";
export default {
  props: [
    "data",
    "type",
    "selectedData",
    "buttonLabel",
    "dropDownTextColor",
    "imageField",
    "customTitle",
    "additionalFields",
    "additionalFieldsPreFix",
    "additionalFieldsPostFix",
    "listType",
    "dateBasedPractitionerTimes",
    "selectedTime",
  ],
  components: {
    List, ListWithTime
  },
  mixins: [colorMixin],
  data() {
    return {
      busy: false,
    };
  },
  computed: {
    imageEnabled() {
      return this.imageField !== false;
    },
    modalVisibilityClass() {
      return this.$store.getters.dropDownModalVisibility(this.type)
        ? ['show', 'showModal']
        : '';
    }
  },
  methods: {
    async selectService(serviceSelectionObj) {
      await this.$store.dispatch(
        'serviceUpdate',
        {
          service: serviceSelectionObj.selected,
          isPractitionerService: serviceSelectionObj.type === "practitioner" ? true : false
        }
      );
    },
    selectPractitioner(practitioner) {
      this.$emit("selectEvent", {selected: practitioner.selected, type: this.type});
      // this.$store.dispatch('setPractitioner', practitioner.selected);
    },
    timeButtonClick(obj) {
      this.$emit("timeButtonClick", obj);
    },
    imageSrc(selectedData) {
      if (!this.imageEnabled) return;

      return selectedData[this.imageField]
        ? selectedData[this.imageField]
        : this.$store.getters.clinic.logo;
    },
    //@todo: remove not in use?
    select(d) {
      this.$emit("selectEvent", {selected: d, type: this.type});
      this.modalShow = false;
      this.closeDropdownModal();
    },
    closeDropdownModal() {
      this.$store.commit('setDropDownModalVisibility', {
        bool: false,
        type: this.type
      });
    }
  }
};
</script>
<style>
.myModalHeader {
  align-items: center !important;
  justify-content: center !important;
}
</style>

<template>
<div class="percentagePadding">
  <div
    class="row collectorWrapper"
    v-if="$store.state.serviceFirst === true"
  >
    <div :class="[colSize]">
      <service v-if="serviceVisible"/>
      <package v-if="packageVisible"/>
    </div>
    <practitioner
      v-if="!$store.state.skipPractitioners"
      :practitionerVisible="practitionerVisible"
    />
  </div>
  <div
    class="row"
    v-else
  >
    <practitioner :practitionerVisible="practitionerVisible" />
    <div :class="[colSize]">
      <service v-if="serviceVisible"/>
      <package v-if="packageVisible"/>
    </div>
  </div>
</div>
</template>
<script>
import Practitioner from './Practitioner.vue';
import Service from './Service.vue';
import Package from './Package.vue';

export default {
  mounted() {
    },
  computed: {
    practitionerVisible() {
      if (this.$store.state.singlePractitioner === true) {
          console.log('skip: single practitioner listing ', this.$store.state.practitioner);
          return false;
      }

      if (this.$store.state.serviceFirst === false) {
        if (!this.$store.state.practitioner) {
          return true;
        }
      } else {

        if (
          !this.$store.state.practitioner
          && (this.$store.state.service || this.$store.state.packageSelectedService)
        ) {
          return true;
        }
      }

      return false;
    },

    serviceVisible() {
      if (this.$store.state.serviceFirst === false) {
        if (
          (this.$store.state.practitioner !== null)
          && (
            !this.$store.state.service
            && !this.$store.state.packageSelected
          )
        ) {
          return true;
        }

      } else {
        if (!this.$store.state.service
            && !this.$store.state.packageSelected
        ) {
          return true;
        }
      }

      return false;
    },

    packageVisible() {
      if (this.$store.state.serviceFirst === false) {
        if (
          this.$store.state.practitioner
          && (
            !this.$store.state.service
            || !this.$store.state.packageSelected
          )
        ) {
          return true;
        }
      } else {
        if (
          !this.$store.state.service
          && !this.$store.state.packageSelectedService
        ) {
          return true;
        }
      }

      return false;
    },

    colSize() {
      let cls = this.practitionerSelected
        ? 'col-md-9'
        : 'col-md-7';

      if (
        this.$store.state.singlePractitionerTileVisible === false
        && this.$store.state.singlePractitioner
      ) {
        cls = 'col-md-12';
      }

      return cls;
    },
    practitionerSelected() {
      return this.$store.state.practitioner ? true : false;
    }
  },
  components: {
    Practitioner,
    Service,
    Package,
  }
};
</script>

<style>
.collectorWrapper {
  min-height: 400px;
}
.percentagePadding {
  padding-left: 5%;
  padding-right: 5%;
}
</style>

<template>
  <div class="row text-center">
    <div
      class="col loadingIcon"
      :style="mixinSpinnerColour()"
    >
      <font-awesome-icon :icon="['fas', 'circle-notch']" />
    </div>
  </div>
</template>

<script>

import colorMixin from "../../mixins/colorMixin";
export default {
  mixins: [colorMixin],
}
</script>

<style>
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loadingIcon svg {
  max-width: 65px;
  max-height: 65px;
  animation: load 1.3s linear infinite;
}
</style>
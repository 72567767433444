<template>
  <div class="termsAndConditions">
    <h5>Terms & Conditions:</h5>
    <div class="alert alert-danger" role="alert" v-if="$store.getters.tncSubmitStatus === 'ERROR'">Please, accept all the mandatory consents!</div>
    <div>
      <div
        class="form-group form-check"
        v-for="(term, index) in $store.getters.terms"
        :key="`tncIndex${index}`"
      >
        <p v-html="term.body"></p>
        <div
          v-for="(consent, consentIndex) in term.consents"
          :key="`consentIndex${consentIndex}`"
        >
          <checkbox
            :name="consent.id"
            :id="consent.id"
            :label="consent.title"
            :mandatory="getMandatory(consent)"
            @updateStore="updateStore"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Checkbox from './Checkbox.vue';

export default {
  data() {
    return {
    }
  },
  components: {
    Checkbox,
  },
  methods: {
    getValidations() {
      let ret = {}
      this.$store.getters.terms.forEach((term) => {
        term.consents.forEach((consent) => {
          if (consent.mandatory) {
            this.$store.commit(
              'setTncValidated',
              { id: consent.id, state: false}
            )
          }
        })
      })
      return ret;
    },
    updateStore(obj) {
      this.$store.commit(
        'setTncValidated',
        obj
      )
    },
    getMandatory(consent) {
      if (consent.mandatory) {
        return true;
      }
      return false;
    }
  },
  async mounted() {
    // await this.$store.dispatch('fetchTerms');
    this.getValidations();
  }
};
</script>

<style>
.termsAndConditions .form-group {
  padding: 0;
}
.termsAndConditions h5 {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 95%;
}
.termsAndConditions label,
.termsAndConditions p {
  font-size: 90%;
}
</style>
import { render, staticRenderFns } from "./ThankYou.vue?vue&type=template&id=8a13cb5a&scoped=true&"
import script from "./ThankYou.vue?vue&type=script&lang=js&"
export * from "./ThankYou.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./ThankYou.vue?vue&type=style&index=0&id=8a13cb5a&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./ThankYou.vue?vue&type=style&index=1&lang=css&")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "8a13cb5a",
  null
  ,true
)

export default component.exports
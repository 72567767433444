<template>
  <div class="col-md-12 border">
    <collector-wrapper v-if="currentStep('practitioner') || currentStep('service')" />
    <event-calendar v-if="currentStep('eventCalendar')" />
    <client-details v-if="currentStep('clientDetails')" />
  </div>
</template>

<script>
import CollectorWrapper from "./CollectorWrapper";
import ClientDetails from "./ClientDetails";
import EventCalendar from './EventCalendar.vue';

export default {
  methods: {
    currentStep(step) {
      return this.$store.getters.isCurrentStep(step)
    }
  },
  components: {
    CollectorWrapper,
    ClientDetails,
    EventCalendar,
  },
};
</script>

<template>
  <div v-if="packagePickerVisible">
    <div class="row">
      <div class="col-md-12 mt-3 mb-2 accordionHeader">
        <button
          type="button"
          :class="packageListToggleOpen ? 'open-accordion' : 'close-accordion'"
          @click="setPackageListToggleOpen"
        >
          <h6>
            Packages
          </h6>
          <svg class="h-5 w-5 text-gray-400" viewBox="0 0 10 10" fill="none" stroke="#000">
            <path d="M7 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>
    </div>
    <list
      v-if="packageListToggleOpen"
      :data="$store.getters.packages"
      :selectedData="$store.getters.package"
      listed="packages"
      visiblePackageServicesCount="2"
      :detailsField="'description'"
      @selected="selectPackage"
      :image-field="$store.state.offeringPhoto ? 'cover_image' : false"
      button-label="Select"
    />
  </div>
</template>

<script>
import colorMixin from "../mixins/colorMixin";
import List from './partials/List.vue';

export default {
  mixins: [colorMixin],
  data() {
    return {
      packageListToggleOpen: true,
    };
  },
  computed: {
    practitionerSelected() {
      return this.$store.getters.practitioner;
    },
    packagePickerVisible() {
      return !this.$store.getters.defaultServiceId
        && this.$store.getters.packages
        && this.$store.getters.packages.length
    }
  },
  methods: {
    setPackageListToggleOpen() {
      this.packageListToggleOpen = !this.packageListToggleOpen;
    },
    async selectPackage(p) {
      this.$store.commit('setPackage', p);
      await this.$store.dispatch('setPackageService');
    },
  },
  components: {
    List,
  },
};
</script>

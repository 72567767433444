<template>
  <div class="enquiry-form-wrapper container">
    <form @submit.prevent="submitForm" v-show="!done">
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="">First name</label>
          <input
            type="text"
            v-model="form.first_name"
            placeholder="First Name"
            class="form-control"
          />
        </div>
        <div class="col-md-6 mb-3">
          <label for="">Last name</label>
          <input
            type="text"
            v-model="form.last_name"
            placeholder="Last Name"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="">Email address</label>
          <input
            type="text"
            v-model="form.email"
            placeholder="Email Address"
            class="form-control"
          />
        </div>
        <div class="col-md-6 mb-3">
          <label for="">Phone number</label>
          <input
            type="number"
            v-model="form.phone"
            placeholder="Phone Number"
            class="form-control"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3" v-if="dob">
          <label for="">Date of birth</label>
          <input
            type="date"
            v-model="form.dob"
            placeholder="Date of Birth"
            class="form-control"
          />
        </div>
        <div class="col-md-6 mb-3" v-if="gender">
          <label for="">Gender</label>
          <select v-model="form.gender" class="form-control">
            <option value disabled>Select an option</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col mb-3">
          <label for="">Questions or comments</label>
          <textarea
            rows="4"
            v-model="form.enquiry"
            placeholder="Questions or comments..."
            class="form-control"
          ></textarea>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-auto ml-auto">
          <button class="btn btn-primary" :style="mixinButtonColors">Submit</button>
        </div>
      </div>
    </form>

    <div class="row mb-3" v-show="done">
      <div class="col">
        <p class="alert alert-success">
          Thank you for your enquiry, a member of the team will be in touch
          soon.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    clinic: {
      type: String,
      default: "",
    },
    btnBgColor: {
      type: String,
      default: "",
    },
    dob: {
      type: Boolean,
      default: false,
    },
    gender: {
      type: Boolean,
      default: false,
    },
    clientEmailTemplateId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      done: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        enquiry: "",
        gender: "",
        dob: "",
        source: window.location.href,
        status: "new",
        clinic_id: this.clinic,
        client_email_template_id: this.clientEmailTemplateId,
      },
    };
  },
  computed: {
    buttonColors() {
      return (
        "background-color: " +
        this.btnBgColor +
        "; border-color: " +
        this.btnBgColor
      );
    },
  },
  methods: {
    submitForm() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/front-end/clinics/enquire",
          this.form
        )
        .then(({ data }) => {
          this.done = true;
          this.$emit("enquirySent", data);
        });
    },
  },
  mounted() {
    console.log("loading the enquiry form");
  },
};
</script>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.css";
</style>
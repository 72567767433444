<template>
  <div
    class="col-md-12 pr-0 elementList timeSelector"
    :class="`list-${listed}`"
    v-on:scroll="handleListScroll"
  >
    <div
      v-if="!selectedEl"
      class="row"
    >
      <div
        :class="[listElColSize, 'list-el']"
        v-for="d in data"
        :key=d.id
      >
        <div
          class="card"
          :style="selectedId === d.id ? mixinListElBgSelected() : ''"
        >
          <div
            class="image"
            @mouseover="infoButtonHoverActive(d.id)"
            @mouseout="infoButtonHoverInactive(d.id)"
            @click="toggleDetailsPanel(d.id)"
          >
            <img
              v-if="imageEnabled"
              :src="imageSrc(d)"
              :class="listed === 'practitioners' ? 'practitionerImage' : ''"
            />
            <div
              class="svgHover"
              v-if="d.id === infoHoveredEl"
            >
              <span :style="mixinButtonColorsSvgHover()">
                <font-awesome-icon :icon="['fas', 'info-circle']"/>
              </span>
            </div>
          </div>
          <div class="card-body text-center p-0 mb-2">
            <h6
              :class="['font-normal']"
              :style="mixinListElementTitleColor()"
            >
              <strong><long-text-show-more
                :text="d.name"
                limit="30"
                :id="d.id"
                showMoreEnabled="false"
                @customClickEvent="customClickEvent"
              /></strong>
            </h6>
          </div>
          <div
            class="detailsPanel"
            v-if="isDetailsPanelOn(d.id) >= 0"
          >
            <button
              class="detailsPanelClose"
              @click="toggleDetailsPanel(d.id)"
            >
              <font-awesome-icon :icon="['fas', 'times']" />
            </button>
            <div
              class="verticallyScrollable"
              @click="toggleDetailsPanel(d.id)"
            >
              <h6
                :class="['font-normal']"
                :style="mixinListElementTitleColor()"
              >
                <strong>{{ d.name }}</strong>
              </h6>
              <!-- Services and Practitioners -->
              <div v-if="listed !== 'packages'">
                {{getDetailsPanelContent(d)}}
              </div>
              <!-- Packages -->
              <div
                v-else
                class="packageServicesFlipped"
              >
                <strong>{{ d.price_display }}</strong>
                <ul>
                  <li
                    v-for="(service, index) in d.services"
                    class="mx-0 my-2"
                    :key="`packageService${index}`"
                  >
                    <strong>{{service.name}}</strong>
                    <div>{{service.practitioner.name}} | {{service.practitioner_service.duration}} mins</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <time-buttons
            :times="dateBasedPractitionerTimes[d.id]"
            @timeButtonClick="timeButtonClick"
            :practitionerId="d.id"
            :selectedTime="selectedTime"
          />
        </div>
      </div>
    </div>
    <div
      v-if="data && Object.keys(data).length > 4"
      class="thereIsMoreScroll"
      :class="listScrolled ? 'hide' : ''"
    >
      There is more, please scroll!
    </div>
  </div>
</template>

<script>
import colorMixin from "../../mixins/colorMixin";
import LongTextShowMore from "./LongTextShowMore";
import TimeButtons from "./TimeButtons";

export default {
  props: [
    "data",
    "selectedId",
    "selectedData",
    "buttonLabel",
    "listed",
    "type",
    "dropDownTextColor",
    "colsInOneRow",
    "imageField",
    "visiblePackageServicesCount",
    "detailsField",
    "additionalFields",
    "additionalFields2",
    "additionalFieldsPreFix",
    "additionalFieldsPostFix",
    "descriptionContentEnabled",
    "dateBasedPractitionerTimes",
    "selectedTime",
  ],
  mixins: [colorMixin],
  data() {
    return {
      modalShow: false,
      selectedEl: false,
      infoHoveredEl: null,
      selectHoveredEl: null,
      listScrolled: false,
      detailsPanelOn: [],
    };
  },
  components: {
    LongTextShowMore,
    TimeButtons,
  },
  watch: {
    selectedDateObj(newVal) {
      console.log(333, newVal)
    },
    selectedData(newVal) {
      this.selectedEl = newVal;
    }
  },
  computed: {
    imageEnabled() {
      return this.imageField !== false;
    },
    listElColSize() {
      const ln = Object.keys(this.data).length;
      if (ln === 1) {
        return 'col-md-12 col-lg-12 col-sm-12'
      }
      if (ln === 2) {
        return 'col-md-6 col-lg-4 col-sm-6'
      }
      if (ln === 3) {
        return 'col-md-4 col-lg-4 col-sm-6'
      }
      if (ln === 4) {
        return 'col-md-3 col-lg-3 col-sm-6'
      }
      return 'col-md-6 col-lg-4 col-sm-12'
    },
    showModal() {
      return this.modalShow ? 'show' : '';
    }
  },
  mounted() {
    console.log(this.selectedDateObj)
  },
  methods: {
    timeButtonClick(time) {
      console.log('lwt', time)
      //@todo select practitioner as well at some point
      this.$emit("timeButtonClick", time);
    },
    customClickEvent(obj) {
      this.toggleDetailsPanel(obj.id)
    },
    getVisibleServices(d) {
      return [...d.services].splice(0, this.visiblePackageServicesCount);
    },
    handleListScroll() {
      if (this.listScrolled) return;
      this.listScrolled = true;
    },
    getDetailsPanelContent(d) {
      if (
        this.descriptionContentEnabled !== false
        && this.listed === 'services'
      ) {
        if (d['service'] && d['service'][this.detailsField]) {
          return d['service'][this.detailsField];
        }
        return d[this.detailsField];
      }
      if (
        this.descriptionContentEnabled !== false
        && this.listed === 'practitioners'
      ) {
        return d[this.detailsField];
      }
    },
    isDetailsPanelOn(id) {
      return this.detailsPanelOn.lastIndexOf(id);
    },
    toggleDetailsPanel(id) {
      const index = this.isDetailsPanelOn(id);
      if (index === -1) {
        this.detailsPanelOn.push(id);
      } else {
        this.detailsPanelOn.splice(index, 1);
      }
    },
    infoButtonHoverHandler(id) {
      // if (id === this.infoHoveredEl) {
      //   return this.mixinButtonColorsSvgHover();
      // }
      return this.mixinButtonColorsSvg();
    },
    infoButtonHoverActive(id) {
      this.infoHoveredEl = id;
    },
    infoButtonHoverInactive(id) {
      this.infoHoveredEl = null;
    },
    selectButtonHoverHandler(id) {
      if (id === this.selectHoveredEl) {
        return this.mixinButtonColorsHover();
      }
      return this.mixinButtonColors();
    },
    selectButtonHoverActive(id) {
      this.selectHoveredEl = id;
    },
    selectButtonHoverInactive(id) {
      this.selectHoveredEl = null;
    },
    imageSrc(d) {
      if (!this.imageEnabled) return;

      return d[this.imageField]
        ? d[this.imageField]
        : (this.$store.getters.clinic && this.$store.getters.clinic.logo ? this.$store.getters.clinic.logo : '' );
    },
    toggleModalVisibility() {
      this.modalShow = !this.modalShow;
    },
    selectData(d) {
      this.selectedEl = d;
      this.$emit("selected", {selected: d, type: this.type});
      this.modalShow = false;
    }
  },
};
</script>
<style>
div.timeSelector .card .font-normal {
  margin: 0;
  height: 30px;
  font-size: 0.9rem;
}
div.timeSelector .card > div.image img {
    max-height: 100%;
    min-height: 82px;
}
div.timeSelector .card > div.image {
  height: 82px;
  min-height: 82px;
}
.detailsPanel .detailsPanelClose:hover {
  color: #000;
}
.detailsPanel .detailsPanelClose {
  color: #888;
  width: 27px;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 1px 8px 1px 8px;
  background: transparent;
}
.detailsPanel .verticallyScrollable {
  width: 100%;
  font-size: 85%;
  padding: 1rem 7%;
}
.detailsPanel {
  z-index: 200;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
.card .card-body h6 {
  padding: 0 7%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.card > div.image .svgHover svg {
  pointer-events: none;
  width: 50px;
  height: 50px;
}
@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}
@keyframes fadeOut {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.card > div.image .svgHover {
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: fade .1s linear;
}
.list-el {
  padding: 0 3% 0 0;
  margin: 0;
  margin-bottom: 3%;
}
.card > div.image {
  position: relative;
  cursor: pointer;
  height: 185px;
  min-height: 185px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-el img.practitionerImage {
  transform: scale(0.7);
}
.list-el img {
  max-height: 100%;
  min-height: 185px;
}
.elementList.list-practitioners {
  margin-bottom: 1rem;
  max-height: 333px;
}
.elementList.list-packages .list-el .card{
  height: 445px;
}
.elementList.list-packages {
  max-height: 475px;
}
.elementList {
  padding-left: 15px;
  max-height: 393px;
  overflow: hidden;
  overflow-y: scroll;
}
.uppercase {
  text-transform: uppercase;
  font-weight: bold;
}
button.buttonInfo svg {
  pointer-events: none;
}
button.buttonInfo {
  border: 0;
  width: 30px;
  padding: 0;
  border-radius: 30px;
  background-color: transparent;
}
button.selectButton {
  border: 1px solid #ddd;
  box-shadow: none !important;
  outline:none !important;
}
.thereIsMoreScroll.hide {
  opacity: 0;
  animation: fadeOut 1s linear;
}
.thereIsMoreScroll {
  position: absolute;
  background: lightgrey;
  color: black;
  bottom: 0;
  right: 0;
  font-size: 80%;
  padding: 1px 1em;
}
div.packageServicesFlipped,
div.packageServices {
  font-size: 90%;
  position: relative;
  margin-bottom: 1rem;
  height: 130px;
}
div.packageServicesFlipped ul {
  font-size: 120%;
  padding-left: 10px;
}
div.packageServices ul {
  margin-bottom: 0.7rem;
  padding-left: 2rem;
  padding-right: 1.2rem;
  font-size: 90%;
}
div.packageServicesFlipped ul,
div.packageServices ul {
  text-align: left;
  list-style-type: none;
  line-height: 1em;
}
.seeMorePackage:hover {
  color: #000;
}
.seeMorePackage {
  cursor: pointer;
  color: #888;
  position: absolute;
  margin-top: -0.4rem;
  right: 2rem;
}
.packageServiceTitle {
  text-align: left;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1rem;
  font-size: 95%;
}
</style>
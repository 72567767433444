<template>
  <div class="mb-2 mt-2">
    <span @click="click" class="titleChopped">
      {{text | formatLongText(limit, open)}}
      <a
        v-if="showMoreVisible"
        class="showMoreLink"
        :style="mixinHighlightStyle()"
      ><strong>show {{showMoreText}}</strong></a>
    </span>
  </div>
</template>
<script>
import colorMixin from "../../mixins/colorMixin";
export default {
  mixins: [colorMixin],
  props: [
    "text",
    "limit",
    "id",
    "showMoreEnabled"
  ],
  methods: {
    click() {
      if (!this.id) {
        this.open = !this.open;
      } else if (this.text && this.text.length > this.limit) {
        this.$emit('customClickEvent', {id: this.id, open: this.open});
      }
    }
  },
  computed: {
    showMoreText() {
      if (this.open) {
        return 'less';
      } else {
        return 'more';
      }
    },
    isShowMoreEnabled() {
      if (this.showMoreEnabled === 'false') {
        return false;
      }
      return true;
    },
    showMoreVisible() {
      if (this.isShowMoreEnabled && this.text && this.text.length > this.limit) {
        return true;
      }
      return false;
    }
  },
  data() {
    return {
      open: false,
    }
  },
  filters: {
    formatLongText(text, limit, open) {
      if (!open && text && text.length > limit) {
        return text.substring(0, parseInt(limit)) + '... ';
      }
      return text;
    },
  }
}
</script>
<style>
a.showMoreLink {
  text-transform: uppercase;
  cursor: pointer;
}
</style>

export default {
  methods: {
    mixinSpinnerColour() {
      return `color: ${this.$store.getters.styles.main.main} !important;`;
    },
    mixinButtonColorsHover() {
      return `background-color: ${this.$store.getters.styles.button.activeColor} !important;
        outline-color: ${this.$store.getters.styles.main.main} !important;
        color: ${this.$store.getters.styles.button.backgroundColor} !important;`;
    },
    mixinButtonColors() {
      return `background-color: ${this.$store.getters.styles.button.backgroundColor} !important;
        outline-color: ${this.$store.getters.styles.main.main} !important;
        color: ${this.$store.getters.styles.button.fontColor} !important;`;
    },
    mixinNextButtonColorsOutlineMain() {
      return `background-color: ${this.$store.getters.styles.button.backgroundColor} !important;
        border-color: ${this.$store.getters.styles.main.main} !important;
        color: ${this.$store.getters.styles.button.fontColor} !important;`;
    },
    mixinNextButtonColors() {
      return `background-color: ${this.$store.getters.styles.button.backgroundColor} !important;
        outline-color: ${this.$store.getters.styles.main.main} !important;
        color: ${this.$store.getters.styles.button.fontColor} !important;`;
    },
    mixinListElBgSelected() {
      return `background-color: ${this.getTransparentColor(this.$store.getters.styles.main.main, 0.1)} !important;`
    },
    mixinBackHoverButtonColors() {
      return `background-color: ${this.getTransparentColor(this.$store.getters.styles.button.disabledColor, 0.3)} !important;
      outline-color: ${this.$store.getters.styles.main.main} !important;
      color: ${this.$store.getters.styles.button.fontColor} !important;`;
    },
    mixinBackButtonColors() {
      return `background-color: ${this.$store.getters.styles.button.backgroundColor} !important;
        outline-color: ${this.$store.getters.styles.main.main} !important;
        color: ${this.$store.getters.styles.button.fontColor} !important;`;
    },
    mixinButtonDisabledColors() {
      return `background-color: ${this.$store.getters.styles.button.disabledColor} !important;
        outline-color: ${this.$store.getters.styles.main.main} !important;
        color: ${this.$store.getters.styles.button.fontColor} !important;`;
    },
    mixinButtonColorsCheckboxColourChecked() {
      return `color: ${this.$store.getters.styles.main.main} !important;`;
    },
    mixinButtonColorsSvgHoverWarning() {
      return `color: rgba(0, 0, 0, 0.3) !important;`;
    },
    mixinButtonColorsCheckboxColour() {
      return `color: rgba(0, 0, 0, 0.1) !important;`;
    },
    mixinButtonColorsSvg() {
      return `background-color: ${this.$store.getters.styles.main.main} !important;
        color: #ffffff !important;`;
    },
    mixinButtonColorsSvgHover() {
      return `color: ${this.$store.getters.styles.main.main} !important;`;
    },
    mixinButtonTextColorActive() {
      return '#fff';
    },
    // Temporarily removed
    // calendarAlternativeDayBackgroundColour() {
    //   return '#b8ffda';
    // },

    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    mixinCalendarActiveDayTextColour() {
      return this.$store.getters.styles.calendar.activeDayColor;
    },
    getTransparentColor(color, strength = '0.2') {
      return `rgba(${this.hexToRgb(color).r}, ${this.hexToRgb(color).g}, ${this.hexToRgb(color).b}, ${strength})`;
    },
    mixinCalendarActiveDayBackgroundColour() {
      return `rgba(${this.hexToRgb(this.$store.getters.styles.calendar.activeDayColor).r}, ${this.hexToRgb(this.$store.getters.styles.calendar.activeDayColor).g}, ${this.hexToRgb(this.$store.getters.styles.calendar.activeDayColor).b}, 0.2)`;
    },
    mixinBackground() {
      return `
        background-image: url("${this.$store.getters.styles.background.image}") !important;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: ${this.$store.getters.styles.background.color} !important;
      `;
    },
    mixinHeader() {
      return `
        background-image: url("${this.$store.getters.styles.header.image}") !important;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: ${this.$store.getters.styles.header.color} !important;
        display: block;
      `;
    },
    mixinListElementTitleColor() {
      return `color: ${this.$store.getters.styles.list.textColor} !important;`;
    },
    mixinListElementBorderColor() {
      return `border-color: ${this.$store.getters.styles.list.borderColor} !important;`;
    },
    mixinDropDownColors() {
      return `color: ${this.$store.getters.styles.dropDown.textColor}
        border-color: ${this.$store.getters.styles.dropDown.borderColor}`;
    },
    mixinHighlightStyle() {
      return `color: ${this.$store.getters.styles.dropDown.textColor}`;
    },
    mixinSelectedCardBorderHeaderColors() {
      return `color: ${this.$store.getters.styles.list.selectedBorderColor} !important;
        border-color: ${this.$store.getters.styles.list.selectedBorderColor} !important;`;
    },
    mixinImageSizingStyle(source, type = '') {
      let imgStyle = 'height: auto;';
      if (source === 'modal-alter-prac-times') {
        imgStyle += 'width: 50%;';
      } else if (source === 'clinic-logo-calendar') {
        imgStyle += 'width: 90%;';
      } else if (source === 'modal-abstract') {
        if (type === 'practitioner') {
          imgStyle += 'width: 35%;';
        } else {
          imgStyle += 'width: 100%;';
        }
      } else {
        imgStyle += 'width: 100%;';
      }
      return imgStyle;
    },
  }
};
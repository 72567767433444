<template>
  <div
    v-if="isPractitionerSectionVisibile"
    :class="[practitionerListOrSelectedOrNoShow, practitionerVisible ? '' : 'd-none']"
  >
    <!-- @todo remove next block if another view dies out completely -->
    <div
      :class="['row', singlePracInListView ? 'col-4' : '']"
      v-if="this.practitioner && !busy"
    >
      <div
        class="card bg-light border-0 shadow-sm"
        style="width: 100%;"
      >
        <div class="card-body text-center">
          <img
            :src="this.practitioner.profile_pic_url"
            :alt="this.practitioner.name"
            width="100%"
          />
          <hr />
          <h5 class="mb-3 text-primary">{{ this.practitioner.name }}</h5>
        </div>
        <div
          class="card bg-light border-0 shadow-sm"
          style="width: 100%;"
          v-if="$store.state.service"
        >
          <div class="row card-body">
            <div class="col-md-12">
              <h6>Selected service:</h6>
              <img
                v-if="$store.state.service.cover_image"
                :src="$store.state.service.cover_image"
                class="card-img-top mx-auto"
                :style="mixinImageSizingStyle()"
              />
              <img
                v-else
                :src="$store.getters.clinic.logo"
                class="card-img-top mx-auto"
                :style="mixinImageSizingStyle()"
              />
            </div>
            <div class="col-md-12 mt-2">
              <h6>{{ $store.state.service.name }}</h6>
              <p>{{ $store.state.service.duration }} mins / {{ $store.state.service.price_display }}</p>
              <p>{{ $store.state.serviceMethod }}</p>
            </div>
          </div>
        </div>
        <div
            class="card bg-light border-0 shadow-sm"
            style="width: 100%;"
            v-if="$store.state.date && $store.state.time"
        >
          <div class="row card-body">
            <div class="col-md-9">
              <p>Date: {{ $store.state.date | formatDate }}</p>
              <p>Time: {{ $store.state.time | formatTimeAmPm }}</p>
            </div>
          </div>
        </div>
      </div>
      <button
        @click="resetPractitioner()"
        type="button"
        v-if="!$store.state.defaultPractitionerId && this.practitioners && Object.keys(this.practitioners).length > 1"
        class="btn-close close reset-practitioner"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      v-else-if="this.practitioners && Object.keys(this.practitioners).length > 0"
      class="row pt-5"
    >
      <list
        :data="$store.getters.practitionersSortedArray"
        :descriptionContentEnabled="$store.getters.practitionerBioEnabled"
        listed="practitioners"
        :colsInOneRow="4"
        :selectedData="this.practitioner"
        :detailsField="'community_description'"
        :type="isClinicService ? 'clinic' : 'practitioner'"
        @selected="selectPractitioner"
        :image-field="$store.state.practitionerPhoto ? 'profile_pic_url' : false"
        button-label="Select"
      />
    </div>
    <div class="row" v-else-if="busyPractitioner">
      <!-- @todo -->
      <div class="col">
        <p class="alert alert-info">
          Loading practitioners, please wait...
        </p>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col">
        <p class="alert alert-info">
          There are no available practitioners to book with at this time.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import colorMixin from "../mixins/colorMixin";
import List from './partials/List.vue';

export default {
  mixins: [colorMixin],
  props: [
    "practitionerVisible"
  ],
  data() {
    return {
      practitionerIds: [],
      packages: [],
    };
  },
  watch: {
    practitioners(newVal, oldVal) {
      this.$store.dispatch('setBusy', false);
    },
  },
  computed: {
    isClinicService() {
      return (
        this.$store.getters.practitioner ? true : false
      );
    },
    practitioners() {
      return this.$store.getters.practitioners;
    },
    practitioner() {
      return this.$store.getters.practitioner;
    },
    busy() {
      return this.$store.getters.busy;
    },
    singlePracInListView() {
      return this.$store.getters.practitioner;
    },
    practitionerListElColMargin() {
      return 'mb-4';
    },
    practitionerListElColSize() {
      return 'col-md-6 col-lg-3 col-sm-12';
    },
    practitionerListOrSelectedOrNoShow() {
      return 'col-md-12 col-lg-12 col-sm-12';
    },
    isPractitionerSectionVisibile() {
      return (
        this.$store.state.singlePractitionerTileVisible === true
        || (this.$store.state.practitioners && Object.keys(this.$store.state.practitioners).length)
        || this.$store.state.practitioner
      );
    },
    busyPractitioner() {
      return this.$store.state.busyProcesses.practitioner;

    }
  },
  methods: {
    resetPractitioner() {
      this.$store.dispatch('setPractitioner', null);
    },
    selectPractitioner(practitioner) {
      this.$store.dispatch('setPractitioner', practitioner.selected);
    },
  },
  components: {
    List,
  },
};
</script>

<style>
  button.reset-practitioner {
    right: 0.5rem;
    top: 0.3rem;
  }
  .btn-close {
    position: absolute;
    top: 0.3rem;
    right: 0.5rem;
    font-size: 2.5em;
  }
</style>

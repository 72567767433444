<template>
  <div
    class="card border-0 summaryTile"
    style="width: 100%; position: inherit !important;"
  >
    <div class="row card-body pl-0 pr-0 mr-0 ml-0">
      <div v-if="!onCalendarPage && !$store.state.headerOn" class="serviceDetails percentagePaddingLocalLeft">
        <p><strong>Clinic:</strong> {{ $store.getters.clinic.name }} </p>
      </div>
      <hr v-if="!onCalendarPage && !$store.state.headerOn" />
      <div class="fullWidth percentagePaddingLocalLeft">
        <drop-down-modal-abstract
          v-if="$store.getters.services"
          :data="$store.getters.services"
          :selectedData="$store.getters.service"
          :selectedDataTitle="$store.getters.service ? this.$options.filters.ucFirst($store.getters.service.name) : ''"
          :resetButtonEnabled="onCalendarPage ? isServiceDropdownResetActive : false"
          :modalActive="isServiceModalActive"
          :type="serviceType"
          @resetEvent="onCalendarPage ? resetServiceDropdown : null"
          :image-field="onCalendarPage && $store.state.offeringPhoto ? 'cover_image' : false"
          buttonLabel="Please select a service"
        />
      </div>
      <div class="fullWidth percentagePaddingLocalLeft">
        <long-text-show-more
          v-if="offeringDescriptionEnabled"
          :text="serviceOrPackageDescription"
          limit="100"
        />
      </div>
      <div v-if="$store.getters.service" class="serviceDetails mb-3 percentagePaddingLocalLeft">
        <p><strong>Duration:</strong> {{ $store.getters.service.duration }} mins</p>
        <p><strong>Price:</strong> {{ price }} </p>
        <p v-if="!onCalendarPage"><strong>Service method:</strong> {{ $store.getters.serviceMethod === "Standard" ? "In person" : $store.getters.serviceMethod }} </p>
      </div>
      <hr />
      <div class="fullWidth percentagePaddingLocalLeft">
        <drop-down-modal-abstract
          v-if="$store.getters.practitioners || $store.getters.practitioner"
          :data="$store.getters.practitioners"
          :selectedData="$store.getters.practitioner"
          :selectedDataTitle="$store.getters.practitioner ? $store.getters.practitioner.name : false"
          :resetButtonEnabled="onCalendarPage ? isPractitionerDropdownResetActive : false"
          :modalActive="isPractitionerModalActive"
          type="practitioner"
          @resetEvent="onCalendarPage ? resetPractitionerDropdown : null"
          :image-field="onCalendarPage && $store.state.practitionerPhoto ? 'profile_pic_url' : false"
          buttonLabel="Please select a practitioner"
        />
        <long-text-show-more
          v-if="practitionerBioEnabled"
          :text="$store.getters.practitioner.community_description"
          limit="100"
        />
      </div>
      <hr v-if="!onCalendarPage" />
      <div v-if="!onCalendarPage" class="serviceDetails mb-3 pt-4 pb-3 percentagePaddingLocalLeft">
        <p><strong :style="'color: '+ mixinCalendarActiveDayTextColour()">Date:</strong> {{ $store.getters.date | formatDate }}</p>
        <p><strong :style="'color: '+ mixinCalendarActiveDayTextColour()">Time:</strong> {{ $store.getters.time | formatTimeAmPm }}</p>
      </div>
    </div>

  </div>
</template>

<script>
import DropDownModalAbstract from "./DropDownModalAbstract";
import LongTextShowMore from "./LongTextShowMore";
import colorMixin from "../../mixins/colorMixin";
export default {
  props: [
    "resetPractitionerDropdown",
    "resetServiceDropdown",
    "eventCalenderPage"

  ],
  mixins: [colorMixin],
  data() {
    return {}
  },
  computed: {
    price() {
      if (this.$store.getters.package
        && this.$store.getters.packageService
        && this.$store.getters.package.price
      ) {
        return this.$store.getters.package.price_display;
      }
      return this.$store.getters.service.price_display;
    },
    serviceOrPackageDescription() {
      return this.$store.getters.package && this.$store.getters.package.description
      ? this.$store.getters.package.description
      : this.$store.getters.service.description
    },
    onCalendarPage() {
      return this.eventCalenderPage ? this.eventCalenderPage : false;
    },
    serviceType() {
      if (this.$store.getters.packageService) {
        return 'package';
      }
      return 'service';
    },
    practitionerSelected() {
      return this.$store.state.practitioner;
    },
    practitionersChanged() {
      return this.$store.state.practitioners;
    },
    serviceChanged() {
      return this.$store.state.service;
    },
    practitionerBioEnabled() {
      return this.$store.state.practitionerBio
        && this.$store.getters.practitioner
        && this.$store.getters.practitioner.community_description
    },
    offeringDescriptionEnabled() {
      return this.$store.state.offeringDescription
        && (
          (this.$store.getters.service && this.$store.getters.service.description)
          || (this.$store.getters.package && this.$store.getters.package.description)
        )
    },
    isPractitionerDropdownResetActive() {
      return (
        this.$store.getters.practitioner !== null
        && this.$store.getters.practitioners
        && this.$store.getters.singleSeatedClinic === false
        && this.$store.getters.defaultPractitionerId === null
      );
    },
    isServiceDropdownResetActive() {
      return (
        this.$store.getters.service !== null
        && this.$store.getters.services.length > 1
        && this.$store.getters.defaultServiceId === null
        && this.$store.getters.packageService === null
      );
    },
    isPractitionerModalActive() {
      return (
        this.onCalendarPage
        && this.$store.getters.singleSeatedClinic === false
        && this.$store.getters.practitioners
        && this.$store.getters.defaultPractitionerId === null
      );
    },
    isServiceModalActive() {
      return (
        this.onCalendarPage
        && this.$store.getters.services.length > 1
        && this.$store.getters.defaultServiceId === null
        && this.$store.getters.packageService === null
      );
    },
  },
  components: {
    DropDownModalAbstract,
    LongTextShowMore,
  }
}
</script>
<style>
  div.summaryTileFrame {
    font-size: 0.85rem;
  }
  .summaryTile hr {
    display: inline-block;
    width: 100%;
  }
  .fullWidth {
    width: 100%;
  }
  @media (max-width: 575.98px) {
    div.percentagePaddingLocalOverride {
      padding-left: 6%;
      padding-right: 6%;
    }
    div.formFrame {
      padding-bottom: 0rem !important;
    }
    .summaryTileFrame {
      border-right: 0;
    }
    .summaryTileFrame .summaryTile > div {
      padding-bottom: 0 !important;
    }
  }
</style>
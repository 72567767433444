<template>
  <div class="row">
    <div class="col-md-12 mx-auto">
      <div
        class="form-check float-left mr-3"
        v-if="$store.getters.service && $store.getters.service.booking_options.allow_standard"
      >
        <input
          class="form-check-input"
          type="radio"
          id="standard"
          value="Standard"
          v-model="serviceMethod"
        />
        <label for="standard">In person</label>
      </div>
      <div
        class="form-check float-left mr-3"
        v-if="$store.getters.service && $store.getters.service.booking_options.allow_video"
      >
        <input
          class="form-check-input"
          type="radio"
          id="video"
          value="Video"
          v-model="serviceMethod"
        />
        <label for="video">Video</label>
      </div>
      <div
        class="form-check float-left"
        v-if="$store.getters.service && $store.getters.service.booking_options.allow_phone"
      >
        <input
          class="form-check-input"
          type="radio"
          id="phone"
          value="Phone"
          v-model="serviceMethod"
        />
        <label for="phone">Phone</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    serviceMethod: {
      get() {
        return this.$store.state.serviceMethod
      },
      set(value) {
        this.$store.commit('setServiceMethod', value)
      }
    }
  }
}
</script>
<style scoped>
label {
  margin-bottom: 0;
  position: relative;
  top: 2px;
}
</style>

<template>
  <div
    class="checkboxComponent"
  >
    <input
      type="checkbox"
      class="form-check-input"
      :id="id"
      :name="name"
      v-model="checkedState"
    >
    <span
      :style="isChecked ? mixinButtonColorsCheckboxColourChecked() : mixinButtonColorsCheckboxColour()"
    >
      <font-awesome-icon
        v-if="isChecked"
        :icon="['fas', 'check-square']"
        @click="toggleState()"
      />
      <font-awesome-icon
        v-else-if="mandatory"
        :icon="['fa', 'square']"
        @click="toggleState()"
      />
      <font-awesome-icon
        v-else
        :icon="['fa', 'square']"
        @click="toggleState()"
      />
    </span>
    <label
      class="form-check-label"
      :for="id"
      @click="labelClick()"
    >
      {{label}}
    </label>
    <small
      role="alert"
      v-if="this.$store.getters.tncValidated[id] === false || (mandatory && !isChecked && $store.getters.tncSubmitStatus === 'ERROR')"
    >
      This is required.
    </small>
  </div>
</template>

<script>
import colorMixin from "../../mixins/colorMixin";
export default {
  mixins: [colorMixin],
  props: [
    "id",
    "name",
    "label",
    "defaultState",
    "mandatory",
  ],
  data() {
    return {
      checkedState: null
    };
  },
  computed: {
    isChecked() {
      return this.checkedState;
    }
  },
  methods: {
    toggleState() {
      this.checkedState = !this.checkedState;
      if (this.mandatory) {
        this.$emit('updateStore', {id: this.id, state: this.checkedState});
      }
    },
    labelClick() {
      if (this.mandatory) {
        this.$emit('updateStore', {id: this.id, state: !this.checkedState});
      }
    }
  },
  mounted() {
    this.checkedState = !!this.defaultState;
  },
}
</script>
<style scoped>
.checkboxComponent small {
  width: 100%;
  color: red;
}
.checkboxComponent input {
  display: none !important;
}
.checkboxComponent svg {
  width: 20px;
  height: 20px;
  margin-right: 1.5%;
}
.checkboxComponent * {
  display: inline-block;
}
.checkboxComponent {
  width: 100%;
  display: inline-block;
  position: relative;
}

</style>
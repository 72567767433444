<template>
  <div class="row">
    <div class="col-md-5 col-sd-12 col-lg-4 maxHeightWithScroll leftSideTile p-0">
      <div
        class="col-md-12 mt-3 serviceMethodSelector percentagePaddingLocalLeft"
        v-if="$store.getters.serviceMethod"
      >
        <service-method></service-method>
      </div>
      <hr
        v-if="$store.getters.serviceMethod"
        class="serviceMethodHr"
      />
      <summary-tile
        :resetPractitionerDropdown="resetPractitionerDropdown"
        :resetServiceDropdown="resetServiceDropdown"
        :eventCalenderPage="true"
      />
    </div>
    <div
      class="col-md-7 col-sd-12 col-lg-8 maxHeightWithScroll"
    >
      <div
        class="row"
        :class="[blurEnabled ? 'blur' : '', 'percentagePaddingLocalRight']"
      >
        <div class="col-md-12 col-sd-12 col-lg-12">
          <h6 :class="!calendarAppears ? 'd-none' : ''" class="mt-3 mb-3 chooseTheDate">
            Choose the date you would like your service to be on.
          </h6>
          <div v-if="alternativeMessageVisible" class="alert alert-primary" role="alert">
             Other options are available on this date, please change your selected practitioner/service or package to book
          </div>
        </div>
        <div :class="['col-sd-12 col-md-12 col-lg-12', calendarGridClasses, 'animateToLeft']">
          <div :class="busyCalendar ? 'd-none' : ''">
            <vc-calendar
              v-if="calendarAppears"
              title-position="left"
              trim-weeks
              ref="vcCalendar"
              :masks="{
                title: 'MMMM YYYY',
                weekdays: window.width > 1000 ? 'WW' : 'W',
              }"
              :available-dates="Object.values(availableDates)"
              @dayclick="selectDate"
              @update:from-page="calendarLoaded"
              @update:to-page="toPageEvent"
              :attributes="calendarAttributes"
            />
            <div class="col-md-12 col-lg-12 pl-0 pr-0">
              <hr
                v-if="selectedDate"
                :style="'border-color: ' + mixinCalendarActiveDayTextColour()"
              />
              <p v-if="selectedDate">
                <strong :style="'color: '+ mixinCalendarActiveDayTextColour()">Date:</strong> {{ selectedDate | formatDate }}
              </p>
              <p v-if="selectedTime">
                <strong :style="'color: '+ mixinCalendarActiveDayTextColour()">Time:</strong> {{ selectedTime | formatTimeAmPm }}
              </p>
            </div>
          </div>
        </div>
        <div :class="['col-sd-12', timeModalGridClasses, 'animateFromRight']">
          <div
            v-if="selectedDateObj"
            v-bind:class="['right-bar', timeModalVisibilityClass]"
          >
            <div class="content">
              <div class="body">
                <time-buttons
                  :times="selectedDateObj.times"
                  @timeButtonClick="timeButtonClick"
                  :practitionerId="$store.getters.practitioner ? $store.getters.practitioner.id : undefined"
                  :selectedTime="selectedTime"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!calendarAppears"
        class="messageBox text-center"
      >
        Please select a service and practitioner to see the available dates.
      </div>
      <div
        v-if="!$store.getters.serviceMethod"
        class="messageBox text-center"
      >
        Please select delivery method to proceed.
        <service-method></service-method>
      </div>
      <div
        v-if="noAvailableDatesInThisMonth"
        class="messageBox text-center"
      >
        Sorry, no available dates in this month.
      </div>
    </div>

    <drop-down-modal
      type="practitioner"
      v-if="$store.getters.practitioners && !showPractitionerModalBasedOnSelectedDay"
      :data="$store.getters.practitioners"
      :selectedData="$store.getters.practitioner"
      :image-field="$store.state.practitionerPhoto ? 'profile_pic_url' : false"
      @selectEvent="selectPractitioner"
    />
    <drop-down-modal
      type="service"
      v-if="$store.getters.services"
      :data="$store.getters.services"
      :selectedData="$store.getters.service"
      :image-field="$store.state.offeringPhoto ? 'cover_image' : false"
      @selectEvent="selectService"
    />
    <!-- only when date selected sooner than practitioner -->
    <drop-down-modal
      type="practitioner"
      v-if="showPractitionerModalBasedOnSelectedDay"
      listType="listWithTime"
      :selectedTime="selectedTime"
      :dateBasedPractitionerTimes="dateBasedPractitionerTimes"
      :data="getPractitionersToModalBasedOnSelectedDay"
      :customTitle="`Available practitioners on ${$options.filters.formatDate(selectedDay)}`"
      :selectedData="$store.getters.practitioner"
      :image-field="$store.state.practitionerPhoto ? 'profile_pic_url' : false"
      @selectEvent="selectPractitionerAndFilterTimes"
      @timeButtonClick="timeButtonClick"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

import ServiceMethod from "./ServiceMethod";
import moment from 'moment';
import colorMixin from "../mixins/colorMixin";
import DropDownModal from "./partials/DropDownModal";
import SummaryTile from "./partials/SummaryTile";
import TimeButtons from "./partials/TimeButtons";

export default {
  mixins: [colorMixin],
  data() {
    return {
      defaultMonthSet: false,
      timeModalVisible: false,
      modalVisible: false,
      selectedDateObj: null,
      availableDates: {},
      alternativeDates: {},
      alternativeDatesOriginal: {},
      previousSelectedDate: null,
      selectedDate: null,
      selectedTime: null,
      alternativeMessageVisible: false,
      noAvailableDatesInThisMonth: false,
      window: {
        width: 0,
        height: 0
      },
      dateBasedPractitionerTimes: {},
    };
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  watch: {
    availableDatesFromStore(newVal) {
      this.availableDates = newVal;
    },
    alternativeDatesFromStore(newVal) {
      this.alternativeDates = newVal;
      //update alternativeDatesOriginal if empty
      if (Object.keys(this.alternativeDatesOriginal).length === 0) {
        this.alternativeDatesOriginal = this.alternativeDates;
      }
    },
  },
  computed: {
    getPractitionersToModalBasedOnSelectedDay() {
      if (this.$store.getters.package && this.$store.getters.package.id) {
        return;
      }
      if (this.showPractitionerModalBasedOnSelectedDay !== true) {
        return;
      }
      let practitionersToReturn = { ...this.$store.getters.practitioners };

      Object.keys(this.alternativeDatesOriginal).forEach((practitionerId) => {
        // console.log(
        //   32,
        //   practitionerId,
        //   this.alternativeDatesOriginal[practitionerId],
        //   this.alternativeDatesOriginal[practitionerId][this.selectedDate],
        //   this.selectedDate
        // );
        if (!this.alternativeDatesOriginal[practitionerId][this.selectedDate]) {
          delete practitionersToReturn[practitionerId]
        }
      })
      console.log(
        'available practitioners',
        practitionersToReturn
      );
      return practitionersToReturn;
    },
    selectedDay() {
      return this.selectedDate
    },
    showPractitionerModalBasedOnSelectedDay() {
      if (this.$store.getters.package && this.$store.getters.package.id) {
        return;
      }
      return this.$store.getters.practitioners
        //only when date selected sooner than practitioner
        && this.$store.getters.loadAllPotentialDatesForPractitioners
        && Object.keys(this.$store.getters.alternativeDates).length > 0
    },
    blurEnabled() {
      return !this.$store.getters.serviceMethod || this.noAvailableDatesInThisMonth;
    },
    calendarGridClasses() {
      let reducedClass = 'reduced';
      //only when date selected sooner than practitioner and package not selected
      if (this.$store.getters.loadAllPotentialDatesForPractitioners === true
        && !this.$store.getters.package
      ) {
        reducedClass = 'full';
      }
      return this.timeModalVisible === true ? reducedClass : 'full';
    },
    timeModalGridClasses() {
      //only when date selected sooner than practitioner and package not selected
      if (this.$store.getters.loadAllPotentialDatesForPractitioners === true
        && !this.$store.getters.package
      ) {
        return false;
      }
      return this.timeModalVisible === true ? 'visible' : 'non-visible';
    },
    calendarAppears() {
      return Object.keys(this.availableDates).length && !this.busyCalendar;
    },
    busyCalendar() {
      return this.$store.getters.busyCalendar;
    },
    busy() {
      return this.$store.getters.busy;
    },
    calendarSelectedAttribute() {
      return {
        highlight: {
          class: 'selectedCalendarDay',
          contentClass: 'selectedCalendarDayContent',
        }
      }
    },
    calendarAttributes() {
      if (
        !this.$store.getters.practitioner
        && !this.$store.getters.availableDates
        && !this.$store.getters.alternativeDates
      ) {
        return [];
      }

      let ret = [];

      //highlights for active days
      if (this.$store.getters.availableDates) {
        let dates = [];
        for (const index in this.$store.getters.availableDates) {
          dates.push(
            moment(index).toDate()
          );
        }
        if (dates.length) {
          ret.push({
            key: 'allAvailable',
            highlight: {
              class: 'highlightedCalendarDay',
              contentClass: 'highlightedCalendarDayContent',
              style: {
                backgroundColor: this.mixinCalendarActiveDayBackgroundColour()
              },
              contentStyle: {
                color: this.mixinCalendarActiveDayTextColour()
              },
            },
            dates,
          });
        }
      }
      //highlights for selected day
      if (this.selectedDate) {
          ret.push(
            {
              key: 'today',
              // highlight: 'red',
              highlight: {
                class: 'selectedCalendarDay',
                contentClass: 'selectedCalendarDayContent',
                style: {
                  backgroundColor: this.mixinCalendarActiveDayTextColour()
                },
                contentStyle: {
                  color: '#ffffff'
                },
              },
              dates: new Date(this.selectedDate),
            },
          );
      }

      //highlights for alternative days
      // if (this.$store.getters.alternativeDates) {
      //   let arr = this.$store.getters.alternativeDates;
      //   let alternativeDates = {};
      //   for (const i in arr) {
      //     for (const j in arr[i]) {
      //       if (
      //         !alternativeDates[j]
      //         && !this.$store.getters.availableDates[j]
      //       ) {
      //         alternativeDates[j] = moment(j).toDate();
      //       }
      //     }
      //   }

      //   if (Object.keys(alternativeDates).length) {
      //     ret.push({
      //       key: 'alternativeAvailability',
      //       highlight: {
      //         style: {
      //           backgroundColor: this.calendarAlternativeDayBackgroundColour()
      //         },
      //         fillMode: 'solid',
      //       },
      //       dates: Object.values(alternativeDates),
      //     });
      //   }
      // }

      return ret;
    },
    availableDatesFromStore() {
      return this.$store.getters.availableDates;
    },
    alternativeDatesFromStore() {
      return this.$store.getters.alternativeDates;
    },
    timeModalVisibilityClass() {
      return this.timeModalVisible ? ['show'] : '';
    },
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    toPageEvent(event) {
      if (!event.month) {
        this.noAvailableDatesInThisMonth = false;
        return;
      }
      const month = event.month.toString().length < 2 ? '0' + event.month : event.month;
      this.noAvailableDatesInThisMonth =
        !Object.keys(this.$store.getters.availableDates).find(
          index => index.lastIndexOf(event.year + '-' + month) > -1
        );
    },
    resetServiceDropdown(type) {
      this.$store.dispatch('serviceUpdate', {
        service: null,
        isPractitionerService: false
      });
    },
    resetPractitionerDropdown(type) {
      this.$store.dispatch('setPractitioner', null);
    },
    selectService(service) {
      this.$store.dispatch('serviceUpdate', {
        service: service.selected,
        isPractitionerService: this.$store.getters.practitioner ? true : false
      });
    },
    selectPractitioner(practitioner) {
      this.$store.dispatch('setPractitioner', practitioner.selected);
    },
    async selectPractitionerAndFilterTimes(practitioner) {
      this.$store.dispatch('setPractitioner', practitioner.selected);
    },
    moveCalendarToActiveDatesMonth() {
      if (this.defaultMonthSet === true) {
        return;
      }
      const currentMonth = moment(new Date()).format('M');
      const activeDates = Object.keys(this.availableDates);
      let minDistance = 0;
      activeDates.map(date => {
        const monthOfTheDate = moment(date, 'YYYY-MM-DD').format('M');
        if (monthOfTheDate === currentMonth) {
          //no need to move the calendar
          return;
        }
        if (monthOfTheDate - currentMonth > minDistance) {
          minDistance = monthOfTheDate - currentMonth;
        }
      });
      const calendar = this.$refs.vcCalendar;
      calendar.move(minDistance);
      calendar.$children[3].$el.style.fill = this.mixinCalendarActiveDayTextColour()
      this.defaultMonthSet = true;
    },
    async calendarLoaded() {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          // this.moveCalendarToActiveDatesMonth();
          resolve();
        }, 200);
      });
      this.calendarAttributes[0].highlight.style.backgroundColor = this.mixinCalendarActiveDayBackgroundColour();
      // this.busyCalendar = false;
    },
    selectTimeFromAlternatives(event, date, practitionerId) {
      this.selectedDate = date;
      this.selectedTime = event.target.value;
      this.$store.commit('setPractitioner', this.$store.state.practitioners[practitionerId]);
      this.$store.commit('setDateTime', {
        date: this.selectedDate,
        time: this.selectedTime
      });
    },
    selectTime(event) {
      this.selectedTime = event.target.value;
      this.$store.commit('setDateTime', {
        date: this.selectedDate,
        time: this.selectedTime
      });
      this.closeTimeModal();
    },
    timeButtonClick(obj) {
      this.selectedTime = obj.time.time;
      this.$store.commit('setDateTime', {
        date: this.selectedDate,
        time: this.selectedTime
      });

      //only when date selected sooner than practitioner
      if (obj.practitionerId
        && this.$store.getters.loadAllPotentialDatesForPractitioners === true
      ) {
        this.selectPractitioner({ selected: this.$store.getters.practitioners[obj.practitionerId]})
        this.$store.commit('setDropDownModalVisibility', {
          bool: false,
          type: 'practitioner'
        });
      }
      this.closeTimeModal();
    },
    modalVisibilityClass() {
      return this.modalVisible ? ['show', 'showModal'] : '';
    },
    toggleTimeModalVisibility() {
      if (this.selectedDate === this.previousSelectedDate) {
        this.timeModalVisible = !this.timeModalVisible;
        return;
      }
      if (this.selectedDate === null) {
        this.timeModalVisible = false;
        return;
      }
      this.timeModalVisible = true;
    },
    closeTimeModal() {
      this.timeModalVisible = false;
    },
    selectDate(date) {
      this.selectedDateObj = null;

      for (const index in this.availableDates) {
        if (this.availableDates[index]['date'] === date.id) {
          this.selectedDateObj = this.availableDates[index];
        }
      }

      //only when date selected sooner than practitioner
      if (this.$store.getters.loadAllPotentialDatesForPractitioners === true) {
        this.dateBasedPractitionerTimes = {};
        //get times for available practitioners on the selected date
        for (const practitionerId in this.alternativeDatesOriginal) {
          for (const dateString in this.alternativeDatesOriginal[practitionerId]) {
            if (dateString === date.id) {
              this.dateBasedPractitionerTimes[practitionerId] = this.alternativeDatesOriginal[practitionerId][dateString].times
            }
          }
        }
      }

      if (!this.selectedDateObj) {
        for (const practitionerId in this.alternativeDates) {
          for (const dateIndex in this.alternativeDates[practitionerId]) {
            if (date.id === dateIndex) {
              this.showAlternativeMessage();
            }
          }
        }
        return;
      }
      if (this.previousSelectedDate === this.selectedDateObj.date) {
        this.selectedDate = null;
      } else {
        this.selectedDate = this.selectedDateObj.date;
      }
      console.log('dateClick', this.selectedDateObj.date, this.$store.getters.date);
      this.toggleTimeModalVisibility();
      this.previousSelectedDate = this.selectedDate;

      if (this.$store.getters.loadAllPotentialDatesForPractitioners === true
        && !this.$store.getters.package
        && this.selectedDate !== null
      ) {
        this.$store.commit('setDropDownModalVisibility', {
          bool: true,
          type: 'practitioner'
        });
      }
    },
    showAlternativeMessage() {
      this.alternativeMessageVisible = true;
      console.log(1)
      setTimeout(() => {
        this.alternativeMessageVisible = false;
        console.log(2)
      }, 3000);
    }
  },
  mounted() {
    this.availableDates = this.$store.getters.availableDates;
    this.alternativeDates = this.$store.getters.alternativeDates;
    this.alternativeDatesOriginal = this.$store.getters.alternativeDates;
    this.selectedDate = this.$store.state.date;
    this.selectedTime = this.$store.state.time;
  },
  components: {
    ServiceMethod,
    DropDownModal,
    SummaryTile,
    TimeButtons,
  }
}
</script>
<style>
.btn.noShadowNoOutline {
  box-shadow: none !important;
  outline: none !important;
}
div.swandoola-appointment-booker {
  font-size: 0.85rem;
}
.percentagePaddingLocalLeft {
  padding-left: 15%;
  padding-right: 15%;
}
.percentagePaddingLocalRight {
  padding-left: 8%;
  padding-right: 8%;
}
.leftSideTile {
  border-right: 1px solid #dee2e6;
}
@media (max-width: 991.98px) {
  div.animateToLeft.reduced {
    margin-right: 0;
  }
  div.animateFromRight.visible .btn-group-vertical {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    width: 100%;
  }
  div.animateFromRight.visible button {
    max-width: 30%;
  }
  div.animateFromRight.visible {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .leftSideTile {
    border-right: 0;
  }
  .vc-weekday {
    font-size: 100%;
    width: 4%;
    margin-right: 2.9%;
  }
}
@media (max-width: 575.98px) {
  .leftSideTile {
    border-right: 0;
  }
}
.messageBox {
  position: absolute;
  padding: 8px 30px;
  border-width: 1px;
  border-style: solid;
  border-color: #DDC;
  background-color: #fff;
  z-index: 200;
  margin: auto;
  left: 27%;
  top: 32%;
  box-shadow: 2px 2px 8px #ddd;
  width: 327px;
}
.chooseTheDate {
  font-size: 100%;
}
div.serviceDetails {
  width: 100%;
}
div.serviceDetails p {
  margin-bottom: 0.3em;
}
.blur {
  opacity: .3;
}
.maxHeightWithScroll {
  max-height: 45em;
  overflow: hidden;
  overflow-y: auto;
}
/* The animation code */
.animateFromRight {
  width: 0;
  overflow: hidden;
}
.animateFromRight .right-bar .btn-group-vertical button {
  display: none;
  width: 0;
  height: 0;
  padding: 0;
  border-radius: 5px !important;
  -webkit-transition: width .1s ease-in-out;
  -moz-transition: width .1s ease-in-out;
  -o-transition: width .1s ease-in-out;
  transition: width .1s ease-in-out;
  animation-delay: .3s;
}
.animateFromRight.visible .right-bar .btn-group-vertical button:hover {
  width: 100%;
  height: 100%;
  margin-bottom: 1em;
  display: inline-block;
  font-size: 100%;
  padding: .5em .7em;
}
.animateFromRight.visible .right-bar .btn-group-vertical button {
  width: 100%;
  height: 100%;
  margin-bottom: 1em;
  display: inline-block;
  font-size: 100%;
  padding: .5em .7em;
  -webkit-transition: width .1s ease-in-out;
  -moz-transition: width .1s ease-in-out;
  -o-transition: width .1s ease-in-out;
  transition: width .1s ease-in-out;
  animation-delay: 1s;
}
.animateFromRight.visible {
  width: 16%;
  -webkit-transition: width .1s ease-in-out;
  -moz-transition: width .1s ease-in-out;
  -o-transition: width .1s ease-in-out;
  transition: width .1s ease-in-out;
  animation-delay: .1s;
}
.animateToLeft {
  width: 100%;
  -webkit-transition: width .1s ease-in-out;
  -moz-transition: width .1s ease-in-out;
  -o-transition: width .1s ease-in-out;
  transition: width .1s ease-in-out;
}
.animateToLeft.reduced {
  overflow: hidden;
  display: block;
  flex: auto;
  width: 60.3%;
  margin-right: 5%;
  -webkit-transition: width .1s ease-in-out;
  -moz-transition: width .1s ease-in-out;
  -o-transition: width .1s ease-in-out;
  transition: width .1s ease-in-out;
}
.right-bar {
  display: inline-block;
  width: 100%;
}
.clinic-title h5 {
  line-height: 5rem;
  text-align: center;
}
.time-picker-modal {
  top: -12rem;
}
.showModal {
  display: block;
  position: absolute;
  overflow: inherit;
}
.showModal.timeModal {
  position: relative;
}
.vc-pane {
  overflow: hidden;
}
.vc-container {
  width: 100%;
  position: relative;
}
.vc-pane-layout {
  display: grid;
}
.vc-container, .vc-container * {
    box-sizing: border-box;
}
.vc-pane-container {
  width: 100%;
  position: relative;
}
.vc-day,
.vc-weekday {
  float: left;
  width: 14%;
  text-align: left;
  font-family: "Inter", sans-serif;
}
.vc-weekday {
  font-weight: bold;
  text-align: left;
  line-height: 10px;
  vertical-align: middle;
}
.vc-day.is-not-in-month .vc-highlights {
  display: none;
}
.vc-day.weekday-position-1 {
}
.vc-weekday,
.vc-day {
  position: relative;
  min-height: 16px;
  z-index: 1;
  padding: 1% 0 1% 0;
  float: left;
  width: 4%;
  text-align: center;
  margin-right: 2.9%;
  font-family: "Inter", sans-serif;
}
.highlightedCalendarDayContent {
  font-weight: bold;
}
.vc-day.in-next-month .vc-day-content,
.vc-day.in-prev-month .vc-day-content {
  display: none;
}
.vc-day-content {
  width: 100%;
  display: inline-block;
}
.weekday-position-1 {
  clear: both;
}
.vc-arrows-container,
.vc-arrows-container .vc-arrow {
  overflow: hidden;
  display: inline-block;
}
.vc-day-content.is-disabled {
  color: #444;
}
.vc-title {
  text-align: left;
  line-height: 44px;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1em;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.vc-arrows-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  cursor: pointer;
}
.vc-arrow {
  position: absolute;
  top: 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: auto;
  color: var(--gray-600);
  border-radius: var(--rounded);
  border: 2px solid transparent;
}
.vc-arrow.is-left {
  right: 22px;
}
.vc-arrow.is-right {
  right: 0px;
}
.vc-arrow > svg {
  transform: scale(0.95);
}
.vc-weeks {
  width: 220%;
}
.vc-header {
  display: flex;
  justify-content: left;
  align-items: left;
  padding: 0;
  position: relative;
}
.vc-popover-content-wrapper {
  --popover-horizontal-content-offset: 8px;
  --popover-vertical-content-offset: 10px;
  --popover-slide-translation: 15px;
  --popover-transition-time: 0.14s ease-in-out;
  --popover-caret-horizontal-offset: 18px;
  --popover-caret-vertical-offset: 8px;
  position: absolute;
  display: block;
  outline: none;
  z-index: 10;
}
.vc-popover-content {
  position: relative;
  outline: none;
  z-index: 10;
  box-shadow: var(--shadow-lg);
}
.vc-nav-popover-container {
  width: 300px;
  color: black;
  font-weight: 300;
  background-color: #fff;
  border: 1px solid;
  border-color: #ddd;
  border-radius: 5px;
  padding: 4px;
  box-shadow: 1px;
}
.vc-nav-header{
  text-align: center;
}
.vc-nav-popover-container span.vc-nav-item {
  display: inline-block;
  width: 95px;
  text-align: center;
}
.vc-dot {
  width: 2px;
  height: 2px;
  background: #f00;
}
.vc-day-content {
  position: relative;
}
.vc-day-layer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}
.vc-day-layer.vc-day-box-center-center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: 50% 50%;
}
div.vc-highlight {
  width: 28px;
  height: 28px;
  border-radius: 25px !important;
  display: inline-block;
}
.close.close-modal {
  font-size: 2.5rem;
}
.serviceMethodHr {
  margin-bottom: 0;
}
</style>
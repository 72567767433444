import { render, staticRenderFns } from "./ServiceMethod.vue?vue&type=template&id=5de48f00&scoped=true&"
import script from "./ServiceMethod.vue?vue&type=script&lang=js&"
export * from "./ServiceMethod.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./ServiceMethod.vue?vue&type=style&index=0&id=5de48f00&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5de48f00",
  null
  ,true
)

export default component.exports
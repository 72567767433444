<template>
  <div
    v-if="$store.state.headerOn && $store.getters.clinic"
    class="card border border-bottom-0 col-md-12 col-lg-12 col-sm-12 radius-0 clinicHeader"
    :style="mixinHeader()"
  >
    <!-- header: {{$store.state.headerOn}}
    align: {{logoAlign}}
    logo: {{logoVisible}}
    name: {{nameEnabled}}
    desc: {{descriptionEnabled}} -->
    <div
      v-if="logoAlign == 'left'"
      class="row left-positioning"
    >
      <div class="col-md-2 col-sm-2 col-lg-1 imgVerticallyCentered">
        <span class="helper"></span>
        <img
          v-if="logoVisible"
          :src="imageSrc"
          alt=""
        />
      </div>
      <div
        v-if="nameEnabled"
        class="clinicName"
        :class="[
          descriptionEnabled ? 'col-md-2 col-sm-2 col-lg-3' : 'col-md-10 col-sm-10 col-lg-11',
        ]"
      >
        <h5>{{$store.getters.clinic.name}}</h5>
      </div>
      <div
        v-if="descriptionEnabled"
        class="col-md-8 col-sm-8 col-lg-8 vertical-aligned-text description"
      >
        <span>{{$store.getters.clinic.description}}</span>
      </div>
    </div>
    <div
      v-else-if="logoAlign == 'center'"
      class="row center-positioning"
    >
      <div class="col-md-12 col-sm-12 col-lg-12 imgVerticallyCentered">
        <img
          v-if="logoVisible"
          :src="imageSrc"
          alt=""
        />
        <h5
          v-if="nameEnabled"
        >
          {{$store.getters.clinic.name}}
        </h5>
      </div>
      <div
        v-if="descriptionEnabled"
        class="col-md-12 col-sm-12 col-lg-12 description"
      >
        <small>{{$store.getters.clinic.description}}</small>
      </div>
    </div>
    <div
      v-else-if="logoAlign == 'right'"
      class="row right-positioning"
    >
      <div
        class="col-md-8 col-sm-8 col-lg-8 description"
        v-if="descriptionEnabled"
      >
        <small>{{$store.getters.clinic.description}}</small>
      </div>
      <div
        class="vertical-aligned"
        :class="[
          descriptionEnabled ? 'col-md-2 col-sm-2 col-lg-3' : 'col-md-10 col-sm-10 col-lg-11',
        ]"
      >
        <span class="helper"></span>
        <h5
          v-if="nameEnabled"
        >
          {{$store.getters.clinic.name}}
        </h5>
      </div>
      <div class="col-md-2 col-sm-2 col-lg-1 imgVerticallyCentered">
        <span class="helper"></span>
        <img
          v-if="logoVisible"
          :src="imageSrc"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import colorMixin from "../mixins/colorMixin";

export default {
  mixins: [colorMixin],
  computed: {
    descriptionEnabled() {
      return this.$store.getters.clinic.description && this.$store.getters.clinic.description.length ? true : false;
    },
    nameEnabled() {
      return this.$store.state.clinicNameVisible !== false;
    },
    logoVisible() {
      return this.$store.state.clinicLogoVisible !== false;
    },
    imageSrc() {
      if (!this.logoVisible || !this.$store.getters.clinic.logo) return;
      return this.$store.getters.clinic.logo;
    },
    logoAlign() {
      return this.$store.state.clinicLogoAlign;
    },
  },
};
</script>
<style>
.radius-0 {
  border-radius: 0;
}
.right-positioning .description {
  text-align: right;
  margin-top: 1em;
  margin-bottom: 1em;
}
.right-positioning img,
.left-positioning img {
  width: 100%;
}
@media (max-width: 991.98px) {
  .right-positioning img,
  .left-positioning img {
    width: 70%;
  }
  .clinicHeader .vertical-aligned.col-sm-10 {
    margin-bottom: .5rem;
  }
  .clinicHeader .imgVerticallyCentered.col-sm-2 img {
    width: 50px;
  }
  .clinicHeader .clinicName.col-sm-2 {
    width: 70%;
  }
}
@media (max-width: 767.98px) {
  .right-positioning img,
  .left-positioning img {
    width: 60%;
  }
  .clinicHeader .clinicName.col-sm-2 {
    width: 70%;
  }
  div.thankYouDetails {
    border-top: 1px solid #ddd;
  }
  div.thankYouDetails span {
    margin: 1rem 0 !important;
  }
}
@media (max-width: 575.98px) {
  .right-positioning img,
  .left-positioning img {
    width: 100%;
  }
  .clinicHeader .imgVerticallyCentered.col-sm-2 {
    width: 100%;
    margin-top: 0;
  }
  .clinicHeader .clinicName.col-sm-2 {
    width: 70%;
  }
  div.description {
    display: none;
  }
}
@media (max-width: 460.98px) {
  .right-positioning img,
  .left-positioning img {
    width: 100%;
  }
  .clinicHeader .clinicName.col-sm-2 {
    width: 70%;
  }
  div.description {
    display: none;
  }
}
div.clinicName.justifyLeft {
  justify-content: left;
}
.clinicName,
.vertical-aligned-text {
  font-size: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clinicName h5 {
  margin-bottom: 0;
}
.vertical-aligned h5 {
  vertical-align: middle;
  height: auto;
  box-sizing: border-box;
  display: inline;
}
div.vertical-aligned {
  text-align: center;
}
.vertical-aligned,
.imgVerticallyCentered {
  white-space: nowrap;
  text-align: center;
  margin: 1em 0;
}
.center-positioning div.description {
  text-align: center;
  margin-bottom: 1em;
}
.center-positioning img {
  max-height: 70px;
  margin-right: 3%;
}
.right-positioning h5,
.right-positioning img,
.right-positioning .description,
.center-positioning h5,
.center-positioning img {
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-block;
}
.center-positioning h5,
.center-positioning img {
  max-width: 100px;
  display: inline-block;
}
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
h5 {
  margin-bottom: 0 !important;
}
</style>
